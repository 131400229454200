import { IAnalyticsProvider } from './AnalyticsProvider';

export class MockAnalyticsProvider implements IAnalyticsProvider {
  sdkEnvironment: string | undefined;

  call(): boolean {
    return true;
  }

  time(): boolean {
    return true;
  }
}

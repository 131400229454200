import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import bem from 'easy-bem';
import { IViewUtils } from '../checkout/ui/types';
import { CheckoutOptions, PrimerCheckout, VaultManagerOptions } from '../types';
import { ClientContext } from '../core/ClientContextFactory';
import { SceneTransition } from '../checkout/SceneTransition';

interface ICheckoutContext {
  options: CheckoutOptions | VaultManagerOptions;
  checkout: PrimerCheckout;
  viewUtils: IViewUtils;
  context: ClientContext;
  transitions: SceneTransition;
  className: typeof bem;
}

const CheckoutContext = createContext<ICheckoutContext | null>(null);

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext) as ICheckoutContext;
  return context;
};

export const { Provider } = CheckoutContext;

import { FunctionalComponent, h } from 'preact';
import { useCallback, useLayoutEffect } from 'preact/hooks';
import { Scene } from '../../enums/Checkout';
import DirectDebitFormScene from '../../payment-methods/go-cardless/DirectDebitFormScene';
import DirectDebitMandateScene from '../../payment-methods/go-cardless/DirectDebitMandateScene';
import CheckoutScene from '../../scenes/CheckoutScene';
import LoadingScene from '../../scenes/LoadingScene';
import VaultManagerScene from '../../scenes/VaultManagerScene';
import { SceneStage } from '../../store/BaseStore';
import { useCheckoutContext } from '../../store/CheckoutContext';
import {
  useCheckoutStore,
  useSelector,
} from '../../store/CheckoutStoreContext';
import { Provider as SceneLoaderProvider } from './SceneLoaderContext';

const Scenes = {
  [Scene.LOADING]: LoadingScene,
  [Scene.CHOOSE_PAYMENT_METHOD]: CheckoutScene,
  [Scene.MANAGE_PAYMENT_METHODS]: VaultManagerScene,
  [Scene.DIRECT_DEBIT_FORM]: DirectDebitFormScene,
  [Scene.DIRECT_DEBIT_MANDATE]: DirectDebitMandateScene,
};

type Props = {
  scene: string;
};

const SceneLoader: FunctionalComponent<Props> = ({ scene }) => {
  const { transitions } = useCheckoutContext();
  const store = useCheckoutStore();
  const sceneStage = useSelector(
    (s) => s.sceneStates[scene]?.stage,
  ) as SceneStage;

  const SceneComponent = Scenes[scene];

  useLayoutEffect(() => {
    (async () => {
      if (sceneStage === SceneStage.Entering) {
        await transitions.onEnter(scene as Scene);
        store.handleSceneEntered(scene);
        store.setErrorMessage(null);
      } else if (sceneStage === SceneStage.Exiting) {
        await transitions.onExit(scene as Scene);
        store.handleSceneExited(scene);
      }
    })();
  }, [sceneStage]);

  const setIsMounted = useCallback(() => {
    store.handleSceneMounted(scene);
  }, []);

  return (
    <SceneLoaderProvider value={{ setIsMounted }}>
      <SceneComponent />
    </SceneLoaderProvider>
  );
};

export default SceneLoader;

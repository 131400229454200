import window from '../../utils/window';
import { ProgressEvent } from '../../enums/Tokenization';
import { parseAmountValue } from '../../utils/parseAmountValue';
import { base64 } from '../../utils/base64';
import { BasePaymentMethod } from '../BasePaymentMethod';
import { locateElement } from '../../utils/dom';
import { Api } from '../../core/Api';
import {
  PurchaseInformation,
  RemotePaymentMethodConfiguration,
  MonetaryAmount,
} from '../../types';
import { ScriptLoader } from '../../utils/ScriptLoader';
import {
  GoogleAPI,
  GooglePayPaymentsClient,
  GooglePayIsReadyToPayRequest,
  GooglePayAllowedPaymentMethod,
  GooglePayOptions,
  GooglePayLoadPaymentDataResponse,
  GooglePayLoadPaymentDataRequest,
} from './types';
import { ClientSessionInfo } from '../../core/ClientContextFactory';
import { ErrorCode, PrimerClientError } from '../../errors';

declare global {
  interface Window {
    google: GoogleAPI;
  }
}

// TODO: migrate this to ts
interface ProgressNotifier {
  emit(type: ProgressEvent, data?: unknown): void;
}

interface GooglePayServiceContext {
  api: Api;
  progress: ProgressNotifier;
  countryCode: string;
  purchaseInfo: Nullable<() => PurchaseInformation>;
  session: ClientSessionInfo;
  scriptLoader: ScriptLoader;
}

interface GooglePayRemoteOptions {
  merchantId: string;
  merchantName: string;
}

type GooglePayRemoteConfig = RemotePaymentMethodConfiguration<GooglePayRemoteOptions>;

const basePaymentMethod: GooglePayAllowedPaymentMethod = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    allowedCardNetworks: [
      'AMEX',
      'DISCOVER',
      'INTERAC',
      'JCB',
      'MASTERCARD',
      'VISA',
    ],
  },
};

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [basePaymentMethod],
};

const isReadyToPayRequest: GooglePayIsReadyToPayRequest = {
  ...baseRequest,
  allowedPaymentMethods: [basePaymentMethod],
};

export class GooglePay extends BasePaymentMethod {
  private context: GooglePayServiceContext;

  private options: GooglePayOptions;

  private remoteConfig: GooglePayRemoteConfig;

  private client: Nullable<GooglePayPaymentsClient>;

  constructor(
    context: GooglePayServiceContext,
    options: GooglePayOptions,
    remoteConfig: GooglePayRemoteConfig,
  ) {
    super('Google Pay');
    this.context = context;
    this.options = options;
    this.remoteConfig = remoteConfig;
    this.client = null;
  }

  async setupAndValidate(): Promise<boolean> {
    const loaded = await this.context.scriptLoader.load(
      'https://pay.google.com/gp/p/js/pay.js',
    );

    if (!loaded) {
      return false;
    }

    if (!window.google) {
      window.console.error('Failed to load google payments scripts');
      return false;
    }

    const { PaymentsClient } = window.google.payments.api;

    this.client = new PaymentsClient({
      environment: this.context.session.production ? 'PRODUCTION' : 'TEST',
    });

    const { result: isReadyToPay } = await this.client.isReadyToPay(
      isReadyToPayRequest,
    );

    if (!isReadyToPay) {
      return false;
    }

    if (!this.context.purchaseInfo) {
      window.console.warn(
        'The purchaseInfo option must be provided in order to use Google Pay',
      );
      return false;
    }

    if (!this.context.countryCode) {
      window.console.warn(
        'The countryCode option must be provided in order to use Google Pay',
      );
      return false;
    }

    return true;
  }

  async mount(): Promise<void> {
    const container = locateElement(this.options.container);

    if (!container) {
      window.console.error(
        `Attempted to mount ${this.displayName} control at ${this.options.container} but the element could not be found`,
      );
      return;
    }

    // Client is now non null
    const client = <GooglePayPaymentsClient>this.client;

    // Show the button
    const button = client.createButton({
      buttonType: this.options.buttonType || 'long',
      buttonColor: this.options.buttonColor || 'default',
      onClick: async () => {
        await this.execute();
      },
    });

    const root = button.firstChild || button;

    container.appendChild(root);
  }

  private async execute(): Promise<void> {
    // Client is now non null
    const client = <GooglePayPaymentsClient>this.client;

    // get purchase info is now non null
    const getPurchaseInfo = <() => PurchaseInformation>(
      this.context.purchaseInfo
    );

    const { totalAmount } = getPurchaseInfo();

    const loadPaymentDataRequest = createLoadPaymentDataRequest(
      this.remoteConfig.options,
      this.context.countryCode,
      totalAmount,
    );

    /**
     * Open the google payment dialog where the customer chooses a payment method
     */
    let paymentInfo: Nullable<GooglePayLoadPaymentDataResponse> = null;

    try {
      paymentInfo = await client.loadPaymentData(loadPaymentDataRequest);
    } catch (e) {
      if (e.statusCode === 'CANCELLED') {
        return;
      }

      if (e.statusMessage) {
        this.context.progress.emit(
          ProgressEvent.TOKENIZE_ERROR,
          e.statusMessage,
        );
      }

      return;
    }

    this.context.progress.emit(ProgressEvent.TOKENIZE_STARTED);

    const { tokenizationData, info } = paymentInfo.paymentMethodData;

    const response = await this.context.api.post('/payment-instruments', {
      paymentInstrument: {
        flow: 'GATEWAY',
        network: info?.cardNetwork || 'OTHER',
        merchant_id: this.remoteConfig.options.merchantId,
        encrypted_payload: base64.encode(tokenizationData.token),
      },
    });

    if (response.error) {
      this.context.progress.emit(
        ProgressEvent.TOKENIZE_ERROR,
        PrimerClientError.fromErrorCode(
          ErrorCode.TOKENIZATION_ERROR,
          response.error,
        ),
      );
      return;
    }

    this.context.progress.emit(ProgressEvent.TOKENIZE_SUCCESS, response.data);
  }
}

function createLoadPaymentDataRequest(
  options: GooglePayRemoteOptions,
  countryCode: string,
  amount: MonetaryAmount,
): GooglePayLoadPaymentDataRequest {
  return {
    ...baseRequest,
    transactionInfo: {
      countryCode,
      currencyCode: amount.currency,
      totalPriceStatus: 'FINAL',
      totalPrice: parseAmountValue(amount.value).asString(),
      totalPriceLabel: 'Total',
    },
    merchantInfo: {
      merchantId: options.merchantId,
      merchantName: options.merchantName,
    },
    allowedPaymentMethods: [
      {
        ...basePaymentMethod,
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'primer',
            gatewayMerchantId: options.merchantId,
          },
        },
      },
    ],
  };
}

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { noop } from '../noop';

type Context = {
  setIsMounted: () => void;
};

const SceneLoaderContext = createContext<Context>({ setIsMounted: noop });

export const { Provider } = SceneLoaderContext;
export const useSceneLoaderContext = () => useContext(SceneLoaderContext);

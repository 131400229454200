import window from '../../utils/window';
import { ScriptLoader } from '../../utils/ScriptLoader';
import { ProgressEvent, PaymentFlow } from '../../enums/Tokenization';
import { BasePaymentMethod } from '../BasePaymentMethod';
import { locateElement } from '../../utils/dom';
import {
  RemotePaymentMethodConfiguration,
  PurchaseInformation,
} from '../../types';
import { Api } from '../../core/Api';
import { PayPalAPI, PayPalStyleOptions, PayPalOptions } from './types';
import { OrderStrategy, VaultStrategy, PayPalStrategy } from './OrderStrategy';
import { ClientSessionInfo } from '../../core/ClientContextFactory';
import { ErrorCode, PrimerClientError } from '../../errors';
import { IStyleManager } from '../../checkout/ui/StyleManager';

declare global {
  interface Window {
    paypal: PayPalAPI;
  }
}

// TODO: migrate this to ts
interface ProgressNotifier {
  emit(type: ProgressEvent, data?: unknown): void;
}

interface PayPalServiceContext {
  api: Api;
  progress: ProgressNotifier;
  purchaseInfo: Nullable<() => PurchaseInformation>;
  session: ClientSessionInfo;
  scriptLoader: ScriptLoader;
}

interface PayPalRemoteOptions {
  clientId: string;
}

export class PayPal extends BasePaymentMethod {
  context: PayPalServiceContext;

  options: PayPalOptions;

  remoteConfig: RemotePaymentMethodConfiguration<PayPalRemoteOptions>;

  paymentFlow: PaymentFlow;

  styleManager: IStyleManager;

  constructor(
    context: PayPalServiceContext,
    options: PayPalOptions,
    remoteConfig: RemotePaymentMethodConfiguration<PayPalRemoteOptions>,
    styleManager: IStyleManager,
  ) {
    super('PayPal');
    this.context = context;
    this.options = options;
    this.remoteConfig = remoteConfig;
    this.styleManager = styleManager;
    this.paymentFlow = options.paymentFlow || context.session.paymentFlow;
  }

  private get strategy(): PayPalStrategy {
    return this.paymentFlow === PaymentFlow.PREFER_VAULT
      ? VaultStrategy
      : OrderStrategy;
  }

  private get createToken(): (
    data: Record<string, unknown>,
  ) => Promise<unknown> {
    return this.strategy.createToken(this);
  }

  async setupAndValidate(): Promise<boolean> {
    if (!this.context.purchaseInfo) {
      window.console.warn(
        'The purchaseInfo option must be provided in order to use PayPal',
      );
      return false;
    }

    const { totalAmount } = this.context.purchaseInfo();

    const params: Record<string, string> =
      this.paymentFlow === PaymentFlow.PREFER_VAULT
        ? { vault: 'true' }
        : { intent: 'authorize' };

    const query = querystring({
      'client-id': this.remoteConfig.options.clientId,
      'disable-funding': ['bancontact', 'card', 'credit'].join(','),
      'currency': totalAmount.currency,
      ...params,
    });

    const src = `https://www.paypal.com/sdk/js?${query}`;

    const loaded = await this.context.scriptLoader.load(src);

    if (!loaded) {
      return false;
    }

    if (!window.paypal) {
      window.console.error('Failed to load paypal scripts');
      return false;
    }

    return true;
  }

  async mount(): Promise<void> {
    const container = locateElement(this.options.container);

    if (!container) {
      window.console.error(
        `Attempted to mount ${this.displayName} control at ${this.options.container} but the element could not be found`,
      );
      return;
    }

    if (!this.context.purchaseInfo) {
      window.console.warn(
        'The purchaseInfo option must be provided in order to use PayPal',
      );
      return;
    }

    const style = getButtonStyle(this.options, this.styleManager);

    const options: Record<string, unknown> = {
      style,
      [this.strategy.methodName]: this.strategy.createOrder(this),
      onApprove: async (approval: Record<string, unknown>) => {
        this.context.progress.emit(ProgressEvent.TOKENIZE_STARTED);

        let data: Nullable<unknown> = null;

        try {
          data = await this.createToken(approval);
        } catch (error) {
          this.context.progress.emit(ProgressEvent.TOKENIZE_ERROR, error);
          return;
        }

        const response = await this.context.api.post(
          '/payment-instruments',
          data,
        );

        if (response.error) {
          this.context.progress.emit(
            ProgressEvent.TOKENIZE_ERROR,
            PrimerClientError.fromErrorCode(
              ErrorCode.TOKENIZATION_ERROR,
              response.error,
            ),
          );
          return;
        }

        this.context.progress.emit(
          ProgressEvent.TOKENIZE_SUCCESS,
          response.data,
        );
      },
    };

    const buttons = window.paypal.Buttons(options);

    buttons.render(this.options.container);
  }
}

function querystring(data: Record<string, string>): string {
  return Object.entries(data)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

function getButtonStyle(
  options: PayPalOptions,
  styleManager: IStyleManager,
): PayPalStyleOptions {
  const {
    buttonColor = 'gold',
    buttonShape = 'rect',
    buttonSize = 'responsive',
    buttonHeight,
    buttonLabel = 'paypal',
    buttonTagline = false,
  } = options;

  return {
    color: buttonColor,
    shape: buttonShape,
    size: buttonSize,
    height: buttonHeight ?? styleManager.getApmButtonStyle()?.height ?? 40,
    label: buttonLabel,
    tagline: buttonTagline,
  };
}

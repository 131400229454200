export const translation = {
  navigateToPaymentMethods: 'Selecciona otra forma de pago',
  payWithCard: 'Pagar con tarjeta',
  cardNumber: 'Número de tarjeta',
  cardExpiry: 'Fecha de vencimiento',
  cardExpiryPlaceholder: 'MM/AA',
  cardCVV: 'CVV',
  cardholderName: 'Nombre del titular de la tarjeta',
  cardholderNamePlaceholder: 'Nombre completo',
  previouslyUsed: 'Previamente usado:',
  forgetPaymentMethods: 'Eliminar métodos de pago',
  forgetSavedCard: 'Eliminar tarjeta Terminada en {{last4Digits}}',
  required: 'Requerido',
  invalid: 'Inválido',
  forgetPayPal: 'Eliminar cuenta de PayPal',
  savePaymentMethod: 'Guardar método de pago',
  managePaymentMethods: 'Administre sus métodos de pago',
  savedPaymentMethodsEmpty:
    'Ingrese los detalles de su tarjeta a continuación para agregar un nuevo método de pago',
  newPaymentMethod: 'Agregar una nueva forma de pago',
  cardEndingWith: 'Tarjeta que termina en',
  otherWaysToPay: 'Otras formas de pago',
  pay: 'Pagar',
  addCard: 'Agregar tarjeta',
  // Payment methods dropdown
  editPaymentMethods: 'Editar métodos de pago guardados',
  confirmCardDeletion: 'Confirmar - Eliminar tarjeta',
  confirmPayPalDeletion: 'Confirmar - Eliminar PayPal',
  delete: 'Eliminar',
  cancel: 'Cancelar',
  // Direct Debit Form
  accountHolder: 'Titular de la Cuenta',
  address: 'Dirección',
  addressLine1: 'Dirección línea 1',
  addressLine2: 'Dirección línea 2',
  fullName: 'Nombre',
  email: 'E-mail',
  city: 'Ciudad',
  postalCode: 'Código Postal',
  setUpDirectDebit: 'Preparar Direct Debit',
};

import { FunctionalComponent } from 'preact';
import { Ref, useMemo } from 'preact/hooks';
import { useCheckoutStore, useSelector } from '../store/CheckoutStoreContext';
import CreditCardForm from '../payment-methods/credit-card/CreditCardForm';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import PaymentMethodButton from '../components/PaymentMethodButton';

type Props = {
  otherPaymentMethodsRef: Ref<HTMLDivElement>;
};

const CheckoutPaymentScene: FunctionalComponent<Props> = ({
  otherPaymentMethodsRef,
}) => {
  const store = useCheckoutStore();

  const paymentMethods = useSelector((s) => s.paymentMethods);

  const paymentMethodButtons = useMemo(() => {
    const buttons: Array<[string, BasePaymentMethod]> = [];

    Object.entries(paymentMethods).forEach(([key, paymentMethod]) => {
      if (key === 'card') {
        return;
      }

      buttons.push([key, paymentMethod]);
    });

    return buttons;
  }, [paymentMethods]);

  return (
    <div
      ref={otherPaymentMethodsRef}
      id='primer-checkout-other-payment-methods'
    >
      <div>
        <div
          id='primer-checkout-apm-button-container'
          class='PrimerCheckout__sceneElement PrimerCheckout__apmButtonContainer'
        >
          {paymentMethodButtons.map(([key, paymentMethod]) => (
            <PaymentMethodButton
              key={paymentMethod.displayName}
              id={key}
              paymentMethod={paymentMethod}
            />
          ))}
        </div>

        {store.hasCard && <CreditCardForm />}
      </div>

      <div></div>
    </div>
  );
};

export default CheckoutPaymentScene;

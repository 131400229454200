interface IFrameMessagePayloadMeta {
  source: string;
  destination: string | null;
  name?: string;
  id?: string;
  replyTo?: string;
  [x: string]: unknown;
}

export interface IFrameInputMessagePayload<T = unknown> {
  type: string;
  meta?: Partial<IFrameMessagePayloadMeta>;
  payload?: T;
}

export interface IFrameMessagePayload<T = unknown> {
  type: string;
  meta: IFrameMessagePayloadMeta;
  payload: T;
}

interface SerializedIFrameMessagePayload {
  // eslint-disable-next-line camelcase
  __primer_web_sdk__?: IFrameMessagePayload;
}

export const IFrameMessage = {
  marshal(data: IFrameInputMessagePayload): string {
    const { type, payload = {}, meta = {} } = data;

    return JSON.stringify({
      __primer_web_sdk__: {
        type,
        payload,
        meta,
      },
    });
  },

  unmarshal(serialized: string): Nullable<IFrameMessagePayload> {
    const json = tryParse(serialized);

    if (typeof json !== 'object') {
      return null;
    }

    if (json == null) {
      return null;
    }

    // eslint-disable-next-line no-underscore-dangle
    return json.__primer_web_sdk__ || null;
  },
};

function tryParse(text: string): SerializedIFrameMessagePayload | null {
  let json = null;

  try {
    json = JSON.parse(text);
  } catch {
    /* ignored */
  }

  return json;
}

import bem from 'easy-bem';
import { render, h } from 'preact';

import { PaymentMethodToken, VaultManagerOptions } from '../types';

import { Vault } from './Vault';

import { locateElement } from '../utils/dom';
import { Translations } from '../assets/translations/Translations';
import { getAttribute, setValue } from './DomUtilities';
import { ElementID, Scene } from '../enums/Checkout';

import { PaymentMethods } from '../payment-methods/PaymentMethods';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import { VaultManagerPaymentMethodConfig } from './VaultManagerPaymentMethodConfig';
import { IFrameEventType } from '../core/IFrameEventType';
import { IFrameMessagePayload } from '../core/IFrameMessage';
import { ClientContext } from '../core/ClientContextFactory';

import { ErrorCode, PrimerClientError } from '../errors';
import { ApiEvent } from '../analytics/constants/enums';
import { createVaultManagerViewUtils } from './ui/ViewUtils';

import {
  createStore,
  Provider as StoreProvider,
} from '../store/CheckoutStoreContext';
import { Provider as CheckoutProvider } from '../store/CheckoutContext';
import VaultRoot from '../scenes/VaultRoot';
import { SceneTransition } from './SceneTransition';

type PaymentMethodMap = Record<string, BasePaymentMethod>;

interface PrimerVaultManager {
  validate(): Promise<{ valid: boolean }>;
  tokenize(): Promise<void>;
}

export class VaultManager {
  static async create(
    context: ClientContext,
    options: VaultManagerOptions,
  ): Promise<PrimerVaultManager> {
    const parent = locateElement(options.container);
    const cardOptions = options.card || {};
    const { cardholderNameRequired = true } = cardOptions;

    if (!parent) {
      return onError(
        `Attempted to mount vault UI at "${options.container}" but the element could not be found`,
      );
    }

    const root = document.createElement('div');
    parent.prepend(root);

    ///////////////////////////////////////////
    // Render
    ///////////////////////////////////////////
    const store = createStore();
    const viewUtils = createVaultManagerViewUtils(store, options);

    const validateForm = async () => {
      let valid = false;

      const { valid: formValid } = await paymentMethods.card.validate();

      let uxValid = true;

      if (cardholderNameRequired) {
        const value = getAttribute(ElementID.CARDHOLDER_NAME_INPUT, 'value');
        const error = value ? null : store.getTranslations()?.required;
        viewUtils.fieldUtils.setErrorState(
          ElementID.CARDHOLDER_NAME,
          error ?? null,
        );
        uxValid = !!value;
      }

      valid = formValid && uxValid;

      return {
        valid,
      };
    };

    async function tokenizeCard() {
      const { valid } = await validateForm();

      if (!valid) {
        return;
      }

      await paymentMethods.card.tokenize();
    }
    const checkout = { validate: validateForm, tokenize: tokenizeCard };

    const vault = new Vault(context);
    store.setVault(vault);
    const transitions = new SceneTransition();

    render(
      <CheckoutProvider
        value={{
          options,
          checkout,
          viewUtils,
          context,
          transitions,
          className: bem('PrimerCheckout'),
        }}
      >
        <StoreProvider value={store}>
          <VaultRoot />
        </StoreProvider>
      </CheckoutProvider>,
      root,
    );

    ///////////////////////////////////////////
    // State
    ///////////////////////////////////////////
    const translation = Translations.get(options.locale);
    store.setIsLoading(true);

    store.produceState((draft) => {
      draft.translation = translation;
      draft.options = {
        showSavePaymentMethod: false,
        hasCard: true,
        hasDirectDebit: false,
      };
    });

    ///////////////////////////////////////////
    // Vault
    ///////////////////////////////////////////
    const vaulted = await vault.fetch();

    const paymentMethods: PaymentMethodMap = await PaymentMethods.create(
      context,
      VaultManagerPaymentMethodConfig.create(context, store, viewUtils, {
        ...options,
        vaultOnly: true,
        onTokenizeError(err: PrimerClientError) {
          switch (err.code) {
            case ErrorCode.DUPLICATE_PAYMENT_METHOD_ERROR:
              break;
            default:
              break;
          }

          if (options.onTokenizeError) {
            options.onTokenizeError(err);
          }
        },
        onTokenizeSuccess(data: PaymentMethodToken) {
          options.onTokenizeSuccess(data);
          store.addVault(data);
        },
      }),
      viewUtils,
    );
    store.setPaymentMethods(paymentMethods);

    vaulted.forEach((pm) => {
      store.addVault(pm);
    });

    context.messageBus.on(
      IFrameEventType.CARDHOLDER_NAME,
      (e: IFrameMessagePayload<string>) => {
        setValue(ElementID.CARDHOLDER_NAME_INPUT, e.payload);
      },
    );

    store.setIsLoading(false);
    store.setScene(Scene.MANAGE_PAYMENT_METHODS);

    context.analytics.call({ event: ApiEvent.loadedVaultManagerUi });

    return checkout;
  }
}

function onError(message: string): PrimerVaultManager {
  window.console.error(message);
  return {
    tokenize: () => Promise.resolve(),
    validate: () => Promise.resolve({ valid: false }),
  };
}

import { createFocusTrap, FocusTrap } from 'focus-trap';
import { Fragment } from 'preact';
import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import { Icons } from '../assets/icons/Icons';
import { OffClickHandler } from '../checkout/OffClickHandler';
import { ElementID } from '../enums/Checkout';
import { useCheckoutContext } from '../store/CheckoutContext';
import { useSelector } from '../store/CheckoutStoreContext';
import { useFirstRender, usePrevious } from '../utils/hooks';
import VaultMenuItem from './VaultMenuItem';

const VaultMenu = () => {
  const { viewUtils, className } = useCheckoutContext();
  const vault = useSelector((s) => s.vault);
  const labels = useSelector((s) => s.translation);

  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [confirmingItemId, setConfirmingItemId] = useState<string | null>(null);

  const focusTrap = useRef<FocusTrap | null>(null);
  const offClickHandler = useRef<OffClickHandler | null>(null);

  const previousIsShowingMenu = usePrevious(isShowingMenu);

  useFirstRender(() => {
    viewUtils.toggleVisibilityAnimated(ElementID.ACTIONS_MENU, false, {
      classPrefix: 'PrimerCheckout__dropDownMenu',
      duration: 0,
    });
  });

  useLayoutEffect(() => {
    if (previousIsShowingMenu === isShowingMenu) {
      return;
    }

    if (isShowingMenu) {
      viewUtils.toggleVisibilityAnimated(ElementID.ACTIONS_MENU, true, {
        classPrefix: 'PrimerCheckout__dropDownMenu',
        duration: 300,
      });

      offClickHandler.current = new OffClickHandler([
        ElementID.ACTIONS_MENU,
        ElementID.ACTIONS,
      ]);

      offClickHandler.current.listen(() => {
        setIsShowingMenu(false);
      });

      // Accessibility
      const menu = document.getElementById(
        ElementID.ACTIONS_MENU,
      ) as HTMLElement;

      focusTrap.current = createFocusTrap(menu, {
        onDeactivate: () => {
          setIsShowingMenu(false);
        },
        returnFocusOnDeactivate: true,
        clickOutsideDeactivates: false,
        allowOutsideClick: true,
      });
      focusTrap.current.activate();
    } else {
      viewUtils.toggleVisibilityAnimated(ElementID.ACTIONS_MENU, false, {
        classPrefix: 'PrimerCheckout__dropDownMenu',
        duration: 300,
      });

      offClickHandler.current?.clear();
      offClickHandler.current = null;

      focusTrap.current?.deactivate({ onDeactivate: undefined });
      focusTrap.current = null;
    }
  }, [isShowingMenu]);

  ///////////////////////////////////////////
  // Cb
  ///////////////////////////////////////////
  const handleVaultClick = () => {
    setIsShowingMenu(!isShowingMenu);
  };

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////
  return (
    <Fragment>
      <button
        type='button'
        id='primer-checkout-actions'
        class={className('editButton', { toggle: isShowingMenu })}
        aria-label={labels?.editPaymentMethods}
        onClick={handleVaultClick}
      >
        <img src={Icons.edit} alt='' />
      </button>

      <div class='PrimerCheckout__dropDownMenuContainer'>
        <dialog
          id='primer-checkout-actions-menu'
          class='PrimerCheckout__dropDownMenu'
          open={isShowingMenu}
          hidden={!isShowingMenu}
          aria-hidden={!isShowingMenu}
        >
          {vault.items.map((vaultedItem) => (
            <VaultMenuItem
              key={vaultedItem.id}
              vaultedItem={vaultedItem}
              isConfirming={vaultedItem.id === confirmingItemId}
              isDisabled={
                !!confirmingItemId && vaultedItem.id !== confirmingItemId
              }
              onDeleteClick={() => setConfirmingItemId(vaultedItem.id)}
              onCancelClick={() => setConfirmingItemId(null)}
              onConfirmClick={() => {
                setConfirmingItemId(null);
                setIsShowingMenu(false);
              }}
            />
          ))}
        </dialog>
      </div>
    </Fragment>
  );
};

export default VaultMenu;

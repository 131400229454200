import { ClassName } from '../../enums/Checkout';
import { toggleClass } from '../../utils/toggleClass';
import { IDomUtils } from './types';

export interface IFieldUtils {
  setErrorState(id: string, error: Nullable<string>): Promise<void>;
}

export class FieldUtils {
  private viewUtils: IDomUtils;

  constructor(viewUtils: IDomUtils) {
    this.viewUtils = viewUtils;
  }

  async setErrorState(id: string, error: Nullable<string>): Promise<void> {
    toggleClass(`${id}-field`, ClassName.ERROR, !!error);

    const elm = window.document.getElementById(`${id}-error`);

    if (!elm) {
      return;
    }

    if (error) {
      elm.textContent = error || '';
    }
    this.viewUtils.toggleVisibilityAnimated(elm, !!error, {
      id,
      duration: 300,
      animateHeight: true,
      autoHeight: true,
      onFinish: () => {
        if (!error) {
          elm.textContent = '';
        }
      },
    });
  }
}

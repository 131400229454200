export enum ErrorCode {
  PRIMER_SERVER_ERROR = 'PRIMER_SERVER_ERROR',
  THREE_DS_SETUP_ERROR = 'THREE_DS_SETUP_ERROR',
  THREE_DS_AUTH_FAILED = 'THREE_DS_AUTH_FAILED',
  THREE_DS_NEGOTIATION_ERROR = 'THREE_DS_NEGOTIATION_ERROR',
  TOKENIZATION_ERROR = 'TOKENIZATION_ERROR',
  DUPLICATE_PAYMENT_METHOD_ERROR = 'DUPLICATE_PAYMENT_METHOD_ERROR',
  CARD_NUMBER_ERROR = 'CARD_NUMBER_ERROR',
}

interface ErrorOptions {
  message: string;
  diagnosticsId?: string;
  errorId?: string;
}

export class PrimerClientError extends Error {
  public code: ErrorCode;

  public message: string;

  public diagnosticsId: Nullable<string>;

  public static fromErrorCode(
    code: ErrorCode,
    options: ErrorOptions,
  ): PrimerClientError {
    return new PrimerClientError(code, options);
  }

  constructor(code: ErrorCode, options: ErrorOptions) {
    super(options.message);
    this.code = code;
    this.diagnosticsId = options.diagnosticsId || null;
  }
}

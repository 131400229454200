import { InputMetadata } from '../types';

export const defaultFieldMetadata: InputMetadata = {
  error: null,
  valid: false,
  active: false,
  dirty: false,
  touched: false,
  submitted: false,
};

import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import { getFirstFocusableChild } from '../checkout/focus';
import { useCheckoutContext } from '../store/CheckoutContext';
import { useCheckoutStore, useSelector } from '../store/CheckoutStoreContext';
import CheckoutVaultScene from './CheckoutVaultScene';
import { delay } from '../utils/delay';
import CheckoutPaymentScene from './CheckoutPaymentScene';
import {
  useSceneEntering,
  useScene,
  useSceneMounting,
  useSceneState,
} from '../utils/hooks';
import { Scene } from '../enums/Checkout';
import { useSceneLoaderContext } from '../utils/SceneLoader/SceneLoaderContext';
import { SceneStage } from '../store/BaseStore';
import {
  useWait,
  WaitForComponents,
} from '../utils/SceneLoader/WaitForComponents';
import { Environment } from '../utils/Environment';

const SCENE = Scene.CHOOSE_PAYMENT_METHOD;

const CheckoutScene = () => {
  const store = useCheckoutStore();
  const { viewUtils, checkout, options, className } = useCheckoutContext();
  const { setIsMounted } = useSceneLoaderContext();
  const sceneState = useSceneState(SCENE);

  const labels = useSelector((s) => s.translation);
  const vault = useSelector((s) => s.vault);

  const [isShowingOtherWaysToPay, setIsShowingOtherWaysToPay] = useState(false);

  const otherPaymentMethodsRef = useRef<HTMLDivElement>();

  const showOtherWaysToPay = (animated: boolean) =>
    new Promise<void>((resolve) => {
      if (isShowingOtherWaysToPay) {
        resolve();
        return;
      }
      setIsShowingOtherWaysToPay(true);

      store.selectVault(null);

      const el = document.getElementById(
        'primer-checkout-other-payment-methods',
      ) as HTMLElement;

      viewUtils.toggleVisibilityAnimated(
        'primer-checkout-other-payment-methods',
        true,
        {
          animateHeight: true,
          autoHeight: true,
          duration: animated ? 500 : 0,
          onFinish: () => resolve(),
        },
      );

      // Accessibility
      setTimeout(() => {
        if (!Environment.get('PRIMER_BUILD_INTEGRATION_BUILDER', false)) {
          const f = getFirstFocusableChild(el);
          f?.focus();
        }
      }, 300);
    });

  const { ready } = useWait(() => {
    setIsMounted();
  }, ['components', 'animation']);

  useSceneMounting(() => {
    (async () => {
      if (!store.hasVault) {
        viewUtils.toggleVisibilityAnimated(
          'primer-checkout-vault-methods',
          false,
          {
            duration: 0,
            animateHeight: true,
            autoHeight: true,
          },
        );

        await showOtherWaysToPay(false);
      }

      ready('animation');
    })();
  }, SCENE);

  useLayoutEffect(() => {
    (async () => {
      if (sceneState?.stage === SceneStage.Entered && !store.hasVault) {
        if (!store.hasVault) {
          viewUtils.toggleVisibilityAnimated(
            'primer-checkout-vault-methods',
            false,
            {
              duration: 500,
              animateHeight: true,
              autoHeight: true,
            },
          );

          await delay(300);

          showOtherWaysToPay(true);
        }
      }
    })();
  }, [sceneState, vault]);

  useScene(() => {
    const handleSubmitButtonClick = async () => {
      if (
        Environment.get('PRIMER_BUILD_INTEGRATION_BUILDER', false) &&
        // @ts-expect-error for integration builder
        options.simulateTokenization
      ) {
        // @ts-expect-error for integration builder
        checkout.card.tokenize();
        return;
      }

      const validation = await checkout.validate();

      if (!validation.valid) {
        return;
      }

      await checkout.tokenize();
    };

    store.addSubmitButtonClickListener(handleSubmitButtonClick);
    return () => {
      store.removeSubmitButtonClickListener(handleSubmitButtonClick);
    };
  }, SCENE);

  useSceneEntering(() => {
    store.setSubmitButtonVisible(true);
    store.setSubmitButtonContent(labels?.pay ?? '[PAY]');
  }, SCENE);

  ///////////////////////////////////////////
  // Cb
  ///////////////////////////////////////////

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////

  return (
    <WaitForComponents onReady={() => ready('components')}>
      <div
        id='primer-checkout-scene-checkout'
        class='PrimerCheckout__scene PrimerCheckout__scene--savedPaymentMethods PrimerCheckout--exited'
      >
        <CheckoutVaultScene>
          <button
            type='button'
            id='primer-checkout-navigate-to-payment-methods'
            class='PrimerCheckout__sceneElement PrimerCheckout__textDivider PrimerCheckout__textButton'
            aria-label={
              !isShowingOtherWaysToPay
                ? labels?.navigateToPaymentMethods
                : labels?.otherWaysToPay
            }
            disabled={isShowingOtherWaysToPay}
            onClick={() => showOtherWaysToPay(true)}
          >
            <span
              class={className('label', {
                primary: !isShowingOtherWaysToPay,
                muted: isShowingOtherWaysToPay,
              })}
            >
              {!isShowingOtherWaysToPay
                ? labels?.navigateToPaymentMethods
                : labels?.otherWaysToPay}
            </span>
          </button>
        </CheckoutVaultScene>

        <CheckoutPaymentScene otherPaymentMethodsRef={otherPaymentMethodsRef} />
      </div>
    </WaitForComponents>
  );
};

export default CheckoutScene;

import { getAttribute, massToggle } from './DomUtilities';
import {
  CheckoutOptions,
  FormState,
  ThreeDSVerification,
  ThreeDSVerificationOptions,
  ThreeDSVerificationResult,
} from '../types';
import { ClassName, ElementID, Scene } from '../enums/Checkout';
import { cardTypeChangeHandler } from './cardTypeChangeHandler';
import { fieldChangeHandler } from './fieldChangeHandler';
import { ProgressEvent, ThreeDSecureStatus } from '../enums/Tokenization';
import { PaymentInstrumentType } from '../enums/Tokens';
import ThreeDSecure from '../three-d-secure/ThreeDSecure';
import { ClientContext } from '../core/ClientContextFactory';
import { createTokenizeSuccessCallback } from './createTokenizeSuccessCallback';
import { ErrorCode, PrimerClientError } from '../errors';
import { IViewUtils } from './ui/types';
import { mergeTokenWithSynthetic3DSError } from './mergeTokenWithSynthetic3DSError';
import CheckoutStore from '../store/CheckoutStore';
import { ApiEvent } from '../analytics/constants/enums';

export const VaultManagerPaymentMethodConfig = {
  create(
    context: ClientContext,
    state: CheckoutStore,
    viewUtils: IViewUtils,
    options: CheckoutOptions & { vaultOnly: boolean },
  ): Record<string, unknown> {
    const { countryCode, locale, card = {} } = options;
    const threeDS = new ThreeDSecure({ context, options });
    threeDS.setup({
      container: `#${ElementID.THREE_DS_MODAL}`,
      onChallengeStart: () =>
        viewUtils.toggleVisibilityAnimated(Scene.THREE_DS, true, {
          duration: 500,
        }),
      onChallengeEnd: () =>
        viewUtils.toggleVisibilityAnimated(Scene.THREE_DS, false, {
          duration: 500,
        }),
    });

    return {
      countryCode,
      locale,
      mountImmediately: false,
      logWarnings: false,
      card: {
        onCardMetadata: cardTypeChangeHandler,
        vault: true,
        cardholderName: () =>
          getAttribute(ElementID.CARDHOLDER_NAME_INPUT, 'value'),
        css: card.css || viewUtils.styleManager.getHostedFieldStyle,
        stylesheets: options?.style?.stylesheets,

        onChange(formState: FormState) {
          if (formState.active) {
            state.selectVault(null);
            massToggle(
              ClassName.SAVED_PAYMENT_METHOD,
              ClassName.SELECTED,
              () => false,
            );
          }
        },
        fields: {
          cardNumber: {
            container: '#primer-checkout-card-number-input',
            placement: 'prepend',
            placeholder: '1234 1234 1234 1234',
            ariaLabel: state.getTranslations()?.cardNumber,
            onChange: fieldChangeHandler(
              'primer-checkout-card-number',
              viewUtils.fieldUtils,
            ),
          },
          expiryDate: {
            container: '#primer-checkout-card-expiry-input',
            placeholder: state.getTranslations()?.cardExpiryPlaceholder,
            ariaLabel: state.getTranslations()?.cardExpiry,
            onChange: fieldChangeHandler(
              'primer-checkout-card-expiry',
              viewUtils.fieldUtils,
            ),
          },
          cvv: {
            container: '#primer-checkout-card-cvv-input',
            placeholder: '•••',
            ariaLabel: state.getTranslations()?.cardCVV,
            onChange: fieldChangeHandler(
              'primer-checkout-card-cvv',
              viewUtils.fieldUtils,
            ),
          },
        },
      },
      onTokenizeProgress(ev) {
        switch (ev.type) {
          case ProgressEvent.TOKENIZE_STARTED:
            viewUtils.store.setIsLoading(true);
            break;
          case ProgressEvent.TOKENIZE_ERROR:
            viewUtils.store.setIsLoading(false);
            break;
          default:
            break;
        }
      },
      onTokenizeStart: options.onTokenizeStart,
      onTokenizeError: options.onTokenizeError,
      async onTokenizeSuccess(data) {
        const onSuccess = createTokenizeSuccessCallback(
          options.onTokenizeSuccess,
          viewUtils,
        );

        if (state.hasVaultedToken(data)) {
          viewUtils.store.setIsLoading(false);
          options.onTokenizeError(
            PrimerClientError.fromErrorCode(
              ErrorCode.DUPLICATE_PAYMENT_METHOD_ERROR,
              {
                message: 'This payment method has already been saved.',
              },
            ),
          );
          return;
        }

        if (data.paymentInstrumentType !== PaymentInstrumentType.CARD) {
          onSuccess(data);
          return;
        }

        if (!options.threeDSecure || !context.session.threeDSecureToken) {
          onSuccess(data);
          return;
        }

        const verifyOptions: Partial<ThreeDSVerificationOptions> =
          options.threeDSecure;

        const verification: ThreeDSVerification = await threeDS.verify({
          token: data.token,
          ...verifyOptions,
        });

        if (verification.error) {
          context.analytics.call({ event: ApiEvent.threeDSecureError });
          onSuccess(mergeTokenWithSynthetic3DSError(data, verification.error));
          return;
        }

        if (verification.status === ThreeDSecureStatus.FAILED) {
          viewUtils.store.setIsLoading(false);
          context.analytics.call({ event: ApiEvent.threeDSecureFailed });

          options.onTokenizeError(
            PrimerClientError.fromErrorCode(ErrorCode.THREE_DS_AUTH_FAILED, {
              message: get3DSFailReason(verification),
            }),
          );
          return;
        }

        if (verification.status === ThreeDSecureStatus.SKIPPED) {
          context.analytics.call({ event: ApiEvent.threeDSecureSkipped });
        }

        if (verification.status === ThreeDSecureStatus.SUCCESS) {
          context.analytics.call({ event: ApiEvent.threeDSecureSuccess });
        }

        onSuccess(verification.data || data);
      },
    };
  },
};

function get3DSFailReason(
  result: null | ThreeDSVerificationResult<ThreeDSecureStatus.FAILED>,
): string {
  const reasonText =
    result &&
    result.data &&
    result.data.threeDSecureAuthentication &&
    result.data.threeDSecureAuthentication.reasonText;

  return reasonText || 'Declined by issuer';
}

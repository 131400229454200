/* eslint-disable no-bitwise */

const byteHexCache = {};

export function uuid(): string {
  const buffer = Array(16).fill(null);
  const bytes = buffer.map(randomByte);

  bytes[6] = (bytes[6] & 0x0f) | 0x40;
  bytes[8] = (bytes[8] & 0x3f) | 0x80;

  const hex = bytes.map(byteToHex);

  return [
    hex.slice(0, 4),
    '-',
    hex.slice(4, 6),
    '-',
    hex.slice(6, 8),
    '-',
    hex.slice(8),
  ].join('');
}

function randomByte(): number {
  return Math.floor(Math.random() * 255);
}

function byteToHex(n: number): string {
  if (!byteHexCache[n]) {
    byteHexCache[n] = (n + 0x100).toString(16).substr(1);
  }

  return byteHexCache[n];
}

import { FunctionalComponent, Fragment } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { PaymentInstrumentType } from '../enums/Tokens';
import { useCheckoutStore, useSelector } from '../store/CheckoutStoreContext';
import { VaultListItem, CardDetails, PayPalDetails } from '../types';
import { toggleClass } from '../utils/toggleClass';
import { ClassName } from '../enums/Checkout';
import { delay } from '../utils/delay';
import { usePresence } from '../utils/AnimatePresence/use-presence';

type VaultedProps = {
  vaultedItem: VaultListItem;
};

const CardContent: FunctionalComponent<VaultedProps> = ({ vaultedItem }) => {
  const details = vaultedItem.details as CardDetails;

  return (
    <Fragment>
      <img
        class='PrimerCheckout__savedPaymentMethodIcon'
        src={details.icon}
        alt={details.network}
      />
      <div class='PrimerCheckout__savedPaymentMethodContent'>
        <span class='PrimerCheckout__label PrimerCheckout__savedPaymentMethodDetail'>
          {details.name}
        </span>
        <span class='PrimerCheckout__label PrimerCheckout__savedPaymentMethodLabel'>
          {details.cardNumber}
        </span>
      </div>
    </Fragment>
  );
};

const Card = {
  Content: CardContent,

  getAriaLabel: ({ vaultedItem }) =>
    `${vaultedItem.details.network} ${vaultedItem.details.name}, ending with ${vaultedItem.details.last4Digits}`,
};

const PayPalContent: FunctionalComponent<VaultedProps> = ({ vaultedItem }) => {
  const details = vaultedItem.details as PayPalDetails;

  return (
    <Fragment>
      <img
        class='PrimerCheckout__savedPaymentMethodIcon'
        src={details.icon}
        alt=''
      />
      <div class='PrimerCheckout__savedPaymentMethodContent'>
        <span class='PrimerCheckout__label PrimerCheckout__savedPaymentMethodDetail'>
          PayPal
        </span>
        <span class='PrimerCheckout__label PrimerCheckout__savedPaymentMethodLabel'>
          {details.email}
        </span>
      </div>
    </Fragment>
  );
};

const PayPal = {
  Content: PayPalContent,

  getAriaLabel: ({ vaultedItem }) => `PayPal ${vaultedItem.details.email}`,
};

const Details = {
  [PaymentInstrumentType.CARD]: Card,
  [PaymentInstrumentType.PAYPAL_VAULTED]: PayPal,
};

type Props = {
  vaultedItem: VaultListItem;
};

const VaultedPaymentMethod: FunctionalComponent<Props> = ({ vaultedItem }) => {
  const store = useCheckoutStore();
  const selectedVault = useSelector((s) => s.vault.selected);
  const isSelected = vaultedItem.id === selectedVault;
  const [isPresent, safeToRemove] = usePresence();
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isPresent) {
      return;
    }

    (async () => {
      const paymentMethodContainer = containerRef.current;
      paymentMethodContainer.style.height = `${paymentMethodContainer.scrollHeight}px`;
      toggleClass(
        paymentMethodContainer,
        `${ClassName.SAVED_PAYMENT_METHOD_CONTAINER}--exiting`,
        true,
      );

      // Refresh layout
      paymentMethodContainer.getBoundingClientRect();
      paymentMethodContainer.style.height = '0px';

      await delay(300);
      safeToRemove?.();
    })();
  }, [isPresent]);

  const handleSelect = () => {
    store.selectVault(vaultedItem.id);
  };

  const { Content, getAriaLabel } = Details[vaultedItem.type];
  const args = { vaultedItem };

  return (
    <div ref={containerRef} class='PrimerCheckout__savedPaymentMethodContainer'>
      <button
        type='button'
        data-token={vaultedItem.id}
        class={`PrimerCheckout__savedPaymentMethod PrimerCheckout__token ${
          isSelected ? 'PrimerCheckout--selected' : ''
        }`}
        aria-label={getAriaLabel?.(args)}
        aria-checked={isSelected}
        role='checkbox'
        onClick={handleSelect}
      >
        {Content && <Content {...args} />}
      </button>
    </div>
  );
};

export default VaultedPaymentMethod;

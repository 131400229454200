import { Api } from '../../core/Api';
import { ProgressEvent } from '../../enums/Tokenization';
import { TokenType } from '../../enums/Tokens';
import { ErrorCode, PrimerClientError } from '../../errors';
import { RemotePaymentMethodConfiguration, Validation } from '../../types';
import { BasePaymentMethod } from '../BasePaymentMethod';
import { DirectDebitOptions } from './types';
import * as validators from './validators';

interface MandateData {
  mandateId: string;
  mandateSchema: string;
}

interface GoCardlessRemoteOptions {
  clientId: string;
}

// TODO: migrate this to ts
interface ProgressNotifier {
  emit(type: ProgressEvent, data?: unknown): void;
}

interface GoCardlessServiceContext {
  api: Api;
  progress: ProgressNotifier;
}

type GoCardlessRemoteConfig = RemotePaymentMethodConfiguration<GoCardlessRemoteOptions>;

export class GoCardless extends BasePaymentMethod {
  private context: GoCardlessServiceContext;

  private options: DirectDebitOptions;

  private id: string;

  private mandate: Nullable<MandateData>;

  constructor(
    context: GoCardlessServiceContext,
    options: DirectDebitOptions,
    remoteConfig: GoCardlessRemoteConfig,
  ) {
    super('Go Cardless');
    this.context = context;
    this.options = options;
    this.id = remoteConfig.id;
    this.mandate = null;
  }

  setupAndValidate(): Promise<boolean> {
    return Promise.resolve(true);
  }

  mount(): Promise<void> {
    return Promise.resolve();
  }

  async tokenize(): Promise<void> {
    this.context.progress.emit(ProgressEvent.TOKENIZE_STARTED);

    if (!this.mandate) {
      this.context.progress.emit(
        ProgressEvent.TOKENIZE_ERROR,
        'validate must be called before tokenize!',
      );
      return;
    }

    const { data, error } = await this.context.api.post(
      '/payment-instruments',
      {
        tokenType: TokenType.MULTI_USE,
        paymentFlow: 'VAULT',
        paymentInstrument: {
          gocardlessMandateId: this.mandate.mandateId,
        },
      },
    );

    if (error) {
      this.context.progress.emit(ProgressEvent.TOKENIZE_ERROR, error);
      console.error(error);
      return;
    }

    this.context.progress.emit(ProgressEvent.TOKENIZE_SUCCESS, data);
  }

  async validate(): Promise<Validation> {
    const name = getValue(this.options.customerName);
    const email = getValue(this.options.customerEmail);
    const addressLine1 = getValue(this.options.customerAddressLine1);
    const addressLine2 = getValue(this.options.customerAddressLine2);
    const city = getValue(this.options.customerCity);
    const postalCode = getValue(this.options.customerPostalCode);
    const iban = getValue(this.options.iban);

    const validationErrors: Record<string, 'required' | 'invalid' | null> = {
      customerName: validators.customerName(name),
      customerEmail: validators.customerEmail(email),
      customerAddressLine1: validators.customerAddressLine1(addressLine1),
      customerCity: validators.customerCity(city),
      customerPostalCode: validators.customerPostalCode(postalCode),
      iban: validators.iban(iban),
    };

    const invalid = Object.values(validationErrors).some(Boolean);

    if (invalid) {
      return {
        valid: false,
        validationErrors,
      };
    }

    const [firstName, ...otherNames] = (name || '').trim().split(' ');

    const payload = {
      id: this.id,
      userDetails: {
        firstName: firstName.trim(),
        lastName: otherNames.join(' ').trim(),
        email,
        addressLine1,
        addressLine2,
        city,
        postalCode,
        countryCode: this.options.customerCountryCode,
      },
      bankDetails: { iban },
    };

    const { error, data } = await this.context.api.post<
      typeof payload,
      MandateData
    >('/gocardless/mandates', payload);

    if (error && /iban/.test(error.message)) {
      return {
        valid: false,
        validationErrors: {
          iban: /iban/i.test(error.message) ? 'invalid' : null,
        },
      };
    }

    if (error || !data) {
      const err = PrimerClientError.fromErrorCode(
        ErrorCode.TOKENIZATION_ERROR,
        error || { message: 'Failed to create mandate' },
      );

      this.context.progress.emit(ProgressEvent.TOKENIZE_ERROR, err);

      return { valid: false, error: err.message };
    }

    this.mandate = data;

    return { valid: true };
  }
}

function getValue(val?: string | FunctionReturning<string>): Nullable<string> {
  if (val == null) {
    return null;
  }

  const value = typeof val === 'function' ? val() : val;

  return value ? value.trim() : value;
}

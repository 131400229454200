import { FunctionalComponent } from 'preact';
import { useLayoutEffect } from 'preact/hooks';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import DirectDebitButton from '../payment-methods/go-cardless/DirectDebitButton';
import { useWaitForComponent } from '../utils/SceneLoader/WaitForComponents';

type Props = {
  id: string;
  paymentMethod: BasePaymentMethod;
  onMounted?: () => void;
};

const ManagedPaymentMethodButton: FunctionalComponent<Props> = ({
  id,
  paymentMethod,
  onMounted,
}) => {
  useLayoutEffect(() => {
    (async () => {
      await paymentMethod.mount();
      onMounted?.();
    })();
  }, []);

  return (
    <div
      id={`primer-checkout-apm-${id}`}
      class='PrimerCheckout__apmButton'
    ></div>
  );
};

const PaymentMethodButton: FunctionalComponent<Props> = ({
  id,
  paymentMethod,
}) => {
  const { wait, ready } = useWaitForComponent();

  useLayoutEffect(() => {
    wait(id);
  }, []);

  const handleMounted = () => {
    ready(id);
  };

  if (id === 'directDebit') {
    return <DirectDebitButton onMounted={handleMounted} />;
  }

  return (
    <ManagedPaymentMethodButton
      id={id}
      paymentMethod={paymentMethod}
      onMounted={handleMounted}
    />
  );
};

export default PaymentMethodButton;

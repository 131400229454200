import { useEffect } from 'preact/hooks';
import { Icons } from '../../assets/icons/Icons';
import { Scene } from '../../enums/Checkout';
import { useCheckoutStore } from '../../store/CheckoutStoreContext';

const DirectDebitButton = ({ onMounted }) => {
  const store = useCheckoutStore();

  useEffect(() => {
    onMounted();
  }, []);

  return (
    <div
      id='primer-checkout-apm-directDebit'
      class='PrimerCheckout__apmButton PrimerCheckout__apmButton--managed'
    >
      <button
        type='button'
        class='PrimerCheckout__directDebitButton'
        id='primer-checkout-direct-debit-button'
        onClick={() => store.setScene(Scene.DIRECT_DEBIT_FORM)}
      >
        <img src={Icons.directDebit} />
      </button>
    </div>
  );
};

export default DirectDebitButton;

import { Icons } from '../../assets/icons/Icons';
import { Scene } from '../../enums/Checkout';
import { useCheckoutContext } from '../../store/CheckoutContext';
import {
  useCheckoutStore,
  useSelector,
} from '../../store/CheckoutStoreContext';
import { delay } from '../../utils/delay';
import {
  useScene,
  useSceneEntering,
  useSceneMounting,
} from '../../utils/hooks';
import { useSceneLoaderContext } from '../../utils/SceneLoader/SceneLoaderContext';

const SCENE = Scene.DIRECT_DEBIT_MANDATE;

const DirectDebitMandateScene = () => {
  const store = useCheckoutStore();
  const { options } = useCheckoutContext();
  const { setIsMounted } = useSceneLoaderContext();
  const directDebitPayment = useSelector((s) => s.paymentMethods).directDebit;
  const directDebitForm = useSelector((s) => s.directDebit);

  const labels = useSelector((s) => s.translation);

  useSceneMounting(() => {
    setIsMounted();
  }, SCENE);

  useSceneEntering(() => {
    (async () => {
      store.setSubmitButtonContent('Confirm Direct Debit');
      await delay(500);

      store.setSmallPrintMessage(
        labels?.directDebitSmallPrint.replace(
          /{{companyName}}/g,
          options.directDebit?.companyName ?? '',
        ) ?? '',
      );
      await delay(500);

      store.setIsLoading(false);
    })();
  }, SCENE);

  useScene(() => {
    const handleSubmitButtonClick = async () => {
      store.setIsLoading(true);
      await delay(500);
      await directDebitPayment.tokenize();
      store.setIsLoading(false);
    };

    store.addSubmitButtonClickListener(handleSubmitButtonClick);
    return () => {
      store.removeSubmitButtonClickListener(handleSubmitButtonClick);
    };
  }, SCENE);

  const handleBackClick = () => {
    store.setScene(Scene.DIRECT_DEBIT_FORM);
  };

  return (
    <div
      class='PrimerCheckout__scene PrimerCheckout__scene--directDebitMandate PrimerCheckout--exited'
      id='primer-checkout-scene-direct-debit-mandate'
    >
      <div class='PrimerCheckout__formHeader'>
        <button
          type='button'
          id='primer-checkout-dd-mandate-go-back'
          class='PrimerCheckout__backButton'
          onClick={handleBackClick}
        >
          {Icons.goBack}
        </button>
        <img src={Icons.directDebit} height='20' />
      </div>
      <div class='PrimerCheckout__sceneElement'>
        <h3 class='PrimerCheckout__title'>SEPA Core Direct Debit Mandate</h3>
      </div>

      <div class='PrimerCheckout__sceneElement PrimerCheckout__mandateItems'>
        <div class='PrimerCheckout__sceneElement PrimerCheckout__mandateItem'>
          {Icons.user}
          <div class='PrimerCheckout__mandateDetail'>
            <span class='PrimerCheckout__label'>{labels?.accountHolder}</span>
            <span
              id='primer-checkout-dd-mandate-customer-name'
              class='PrimerCheckout__label'
            >
              {directDebitForm.customerName}
            </span>
          </div>
        </div>

        <div class='PrimerCheckout__sceneElement PrimerCheckout__mandateItem'>
          {Icons.location}
          <div class='PrimerCheckout__mandateDetail'>
            <span class='PrimerCheckout__label'>{labels?.address}</span>
            <span
              id='primer-checkout-dd-mandate-customer-address'
              class='PrimerCheckout__label'
            >
              {directDebitForm.customerAddress}
            </span>
          </div>
        </div>

        <div class='PrimerCheckout__sceneElement PrimerCheckout__mandateItem'>
          {Icons.hash}
          <div class='PrimerCheckout__mandateDetail'>
            <span class='PrimerCheckout__label'>IBAN</span>
            <span
              id='primer-checkout-dd-mandate-iban'
              class='PrimerCheckout__label'
            >
              {directDebitForm.customerIban}
            </span>
          </div>
        </div>
      </div>

      <div class='PrimerCheckout__sceneElement PrimerCheckout--centered'>
        <p
          class='PrimerCheckout__label PrimerCheckout__label--muted'
          id='primer-checkout-dd-creditor-details'
        >
          {[
            options.directDebit?.companyName,
            options.directDebit?.companyAddress,
          ].join(', ')}
        </p>
      </div>
    </div>
  );
};

export default DirectDebitMandateScene;

import { getSystemLocale } from '../../utils/getSystemLocale';
import { translation as deDE } from './de_DE';
import { translation as esES } from './es_ES';
import { translation as frFR } from './fr_FR';
import { translation as enGB } from './en_GB';
import { translation as itIT } from './it_IT';

type TranslationKey = string;

export type TranslationUnit = Record<TranslationKey, string>;

const allTranslations: Record<string, Partial<TranslationUnit>> = {
  en: enGB,
  enGB,
  it: itIT,
  itIT,
  de: deDE,
  deDE,
  es: esES,
  esES,
  fr: frFR,
  frFR,
};

export const Translations = {
  get(twoOrFiveCharCode: string = getSystemLocale()): TranslationUnit {
    return getByCode(twoOrFiveCharCode.replace('_', ''));
  },
};

function getByCode(twoOrFourDigitCode: string): TranslationUnit {
  const unit = allTranslations[twoOrFourDigitCode];

  if (unit || twoOrFourDigitCode.length === 2) {
    return { ...enGB, ...(unit || {}) };
  }

  return getByCode(twoOrFourDigitCode.slice(0, 2));
}

import { FunctionalComponent } from 'preact';
import { useSelector } from '../store/CheckoutStoreContext';
import VaultedPaymentMethod from '../components/VaultedPaymentMethod';
import VaultMenu from '../components/VaultMenu';
import { AnimatePresence } from '../utils/AnimatePresence';

type Props = Record<string, never>;

const CheckoutVaultScene: FunctionalComponent<Props> = ({ children }) => {
  const vault = useSelector((s) => s.vault);
  const labels = useSelector((s) => s.translation);

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////

  return (
    <div id='primer-checkout-vault-methods'>
      <div
        id='primer-checkout-vault'
        class='PrimerCheckout__sceneElement PrimerCheckout__savedPaymentMethods'
      >
        <div class='PrimerCheckout__savedPaymentMethods__title'>
          <div class='PrimerCheckout__label PrimerCheckout__label--muted'>
            {labels?.previouslyUsed}
          </div>
          <div class='PrimerCheckout__savedPaymentMethodActions'>
            <VaultMenu />
          </div>
        </div>

        <AnimatePresence>
          {vault.items.map((vaultedItem) => (
            <VaultedPaymentMethod
              key={vaultedItem.id}
              vaultedItem={vaultedItem}
            />
          ))}
        </AnimatePresence>
      </div>

      {children}

      <div></div>
    </div>
  );
};

export default CheckoutVaultScene;

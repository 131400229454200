import { ClassName } from '../enums/Checkout';
import { InputMetadata } from '../types';
import { toggleClass } from '../utils/toggleClass';
import { IFieldUtils } from './ui/FieldUtils';

export function fieldChangeHandler(
  id: string,
  fieldUtils: IFieldUtils,
): Callable<[{ meta: InputMetadata }], void> {
  return ({ meta }: { meta: InputMetadata }): void => {
    const hasError = Boolean(meta.submitted && meta.error);
    toggleClass(`${id}-input`, ClassName.FOCUSED, meta.active);
    fieldUtils.setErrorState(id, hasError ? meta.error : null);
  };
}

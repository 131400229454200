import ErrorMessage from '../components/ErrorMessage';
import SmallPrint from '../components/SmallPrint';
import SubmitButton from '../components/SubmitButton';
import { useSelector } from '../store/CheckoutStoreContext';
import SceneLoader from '../utils/SceneLoader/SceneLoader';
import ThreeDSScene from './ThreeDSScene';

const VaultRoot = () => {
  const isLoading = useSelector((s) => s.isLoading);
  const sceneStates = useSelector((s) => s.sceneStates);

  return (
    <div
      id='primer-checkout'
      class={`PrimerCheckout ${isLoading ? `PrimerCheckout--loading` : ''}`}
    >
      <div id='primer-checkout-content' class='PrimerCheckout__content'>
        {Object.keys(sceneStates).map((key) => (
          <SceneLoader key={key} scene={key} />
        ))}
      </div>
      <ThreeDSScene />
      <SubmitButton />
      <SmallPrint />
      <ErrorMessage />
    </div>
  );
};

export default VaultRoot;

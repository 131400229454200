type ValidationResult = 'invalid' | 'required' | null;

interface ValidatorOptions {
  required?: boolean;
  regex?: RegExp;
}

interface Validator {
  (value: Nullable<string>): ValidationResult;
}

export const customerName = validator({ required: true, regex: /\s+/ });
export const customerEmail = validator({ required: true });
export const customerAddressLine1 = validator({ required: true });
export const customerCity = validator({ required: true });
export const customerPostalCode = validator({ required: true });
export const iban = validator({ required: true });

function validator(options: ValidatorOptions = {}): Validator {
  return (value) => {
    const normalized = (value || '').trim();

    if (options.required && !normalized) {
      return 'required';
    }

    if (options.regex && !options.regex.test(normalized)) {
      return 'invalid';
    }

    return null;
  };
}

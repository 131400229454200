import { PaymentMethods } from './payment-methods/PaymentMethods';
import ThreeDSecure from './three-d-secure/ThreeDSecure';
import { ThreeDSecureStatus, PaymentFlow } from './enums/Tokenization';
import { Environment } from './utils/Environment';
import { Checkout } from './checkout/Checkout';
import { CheckoutUXFlow } from './enums/Checkout';
import { VaultManager } from './checkout/VaultManager';
import { ClientContextFactory } from './core/ClientContextFactory';

const version = Environment.get('PRIMER_SDK_VERSION');

export default class PrimerClient {
  constructor(options) {
    this.options = options;
  }

  async render(options) {
    const context = await ClientContextFactory.create(this.options);
    return PaymentMethods.create(context, options);
  }

  async checkout(options) {
    const context = await ClientContextFactory.create(this.options);

    return options.uxFlow === CheckoutUXFlow.MANAGE_PAYMENT_METHODS
      ? VaultManager.create(context, options)
      : Checkout.create(context, options);
  }

  get threeDSecure() {
    return new ThreeDSecure({ options: this.options });
  }
}

PrimerClient.CheckoutUXFlow = CheckoutUXFlow;
PrimerClient.ThreeDSecureStatus = ThreeDSecureStatus;
PrimerClient.PaymentFlow = PaymentFlow;

PrimerClient.SDK_VERSION = version;

import {
  AlternativePaymentMethodButtonStyle,
  CheckoutStyle,
} from '../../styles';
import { JSSStyleManager } from './style/JSS';

export interface IStyleManager {
  setStyle(style?: CheckoutStyle): void;
  getHostedFieldStyle(): string | null;
  getApmButtonStyle(): AlternativePaymentMethodButtonStyle | null;
}

export function createStyleManager(): IStyleManager {
  return new JSSStyleManager();
}

import window from '../../utils/window';
import { parseAmountValue } from '../../utils/parseAmountValue';
import { addEventListener } from '../../utils/addEventListener';
import { toggleClass } from '../../utils/toggleClass';
import { BasePaymentMethod } from '../BasePaymentMethod';
import { locateElement } from '../../utils/dom';
import { ProgressEvent } from '../../enums/Tokenization';
import { PrimerClientError, ErrorCode } from '../../errors';

const API_VERSION_NUMBER = 3;

export default class ApplePay extends BasePaymentMethod {
  constructor(context, options, remoteConfig) {
    super('Apple Pay');
    this.context = context;
    this.options = options;
    this.remoteConfig = remoteConfig;
    this.session = null;
  }

  setupAndValidate() {
    const { ApplePaySession } = window;

    if (!ApplePaySession) {
      // Apple Pay is not available in this context
      return false;
    }

    if (!ApplePaySession.canMakePayments()) {
      // The customer has no payment methods det up
      return false;
    }

    const domain = window.location.hostname;
    const configuredDomains = this.remoteConfig.options.merchantDomains || [];

    const isConfigured = configuredDomains.includes(domain);

    if (!isConfigured) {
      window.console.warn(
        `Apple pay has not been configured for domain "${domain}"`,
      );
      return false;
    }

    if (!this.context.purchaseInfo) {
      window.console.warn(
        'The purchaseInfo option must be provided in order to use Apple Pay',
      );
      return false;
    }

    if (!this.context.countryCode) {
      window.console.warn(
        'The countryCode option must be provided in order to use Apple Pay',
      );
      return false;
    }

    return true;
  }

  async mount() {
    const container = locateElement(this.options.container);

    if (!container) {
      window.console.error(
        `Attempted to mount ${this.displayName} control at ${this.options.container} but the element could not be found`,
      );
      return;
    }

    const { ApplePaySession } = window;

    const button = window.document.createElement('div');

    const {
      buttonType = 'plain',
      buttonStyle = 'black',
      buttonHeight = 40,
    } = this.options;

    const style = [
      'display: inline-block',
      'width: 100%',
      'cursor: pointer',
      `height: ${buttonHeight}px`,
      '-webkit-appearance: -apple-pay-button',
      `-apple-pay-button-type: ${buttonType}`,
      `-apple-pay-button-style: ${buttonStyle}`,
    ].join(';');

    button.setAttribute('style', `${style};`);

    toggleClass(button, 'primer-apple-pay-button', true);

    container.appendChild(button);

    addEventListener(button, 'click', () => {
      const { totalAmount } = this.context.purchaseInfo();

      const request = {
        countryCode: this.context.countryCode,
        currencyCode: totalAmount.currency,
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
        merchantCapabilities: ['supports3DS'],
        total: {
          type: 'final',
          label: this.remoteConfig.options.merchantName,
          amount: parseAmountValue(totalAmount.value).asString(),
        },
      };

      this.session = new ApplePaySession(API_VERSION_NUMBER, request);

      this.session.onvalidatemerchant = onValidateSession(this);

      this.session.onpaymentauthorized = onPaymentAuthorized(this);

      this.session.oncancel = () => {
        /* TODO : emit an event for this */
        this.session = null;
      };

      this.session.begin();
    });
  }
}

/**
 *
 * @param {ApplePay} instance
 */
function onValidateSession(instance) {
  return async function onMerchantValidate(event) {
    const response = await instance.context.api.post('/apple-pay/sessions', {
      paymentMethodConfigId: instance.remoteConfig.id,
      validationUrl: event.validationURL,
      merchantDomain: window.location.hostname,
    });

    if (response.error) {
      const error = PrimerClientError.fromErrorCode(
        ErrorCode.PRIMER_SERVER_ERROR,
        response.error,
      );

      window.console.error('Failed to create Apple Pay session', error);

      return instance.context.progress.emit(
        ProgressEvent.TOKENIZE_ERROR,
        error,
      );
    }

    return event.target.completeMerchantValidation(response.data);
  };
}

/**
 *
 * @param {ApplePay} instance
 */
function onPaymentAuthorized(instance) {
  return async function handleAuthorizedPayment(event) {
    const { ApplePaySession } = window;
    const { token } = event.payment;

    instance.context.progress.emit(ProgressEvent.TOKENIZE_STARTED);

    const response = await instance.context.api.post('/payment-instruments', {
      paymentInstrument: {
        paymentMethodConfigId: instance.remoteConfig.id,
        token,
      },
    });

    if (response.error) {
      const error = PrimerClientError.fromErrorCode(
        ErrorCode.TOKENIZATION_ERROR,
        response.error,
      );
      window.console.error('Failed to tokenize instrument', error);
      instance.context.progress.emit(ProgressEvent.TOKENIZE_ERROR, error);
      event.target.completePayment(ApplePaySession.STATUS_FAILURE, [
        error.message,
      ]);
      return;
    }

    instance.context.progress.emit(
      ProgressEvent.TOKENIZE_SUCCESS,
      response.data,
    );

    event.target.completePayment(ApplePaySession.STATUS_SUCCESS);
  };
}

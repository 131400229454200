import {
  EffectCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'preact/hooks';
import { Scene } from '../enums/Checkout';
import { SceneStage } from '../store/BaseStore';
import { useSelector } from '../store/CheckoutStoreContext';
import { noop } from './noop';

export const usePrevious = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useIsFirstRender = () => {
  const ref = useRef(true);
  useLayoutEffect(() => {
    ref.current = false;
  });
  return ref.current;
};

export const useFirstRender = (cb: () => void) => {
  const isFirstRender = useIsFirstRender();

  useLayoutEffect(() => {
    if (!isFirstRender) {
      return;
    }
    cb();
  }, [isFirstRender]);
};

export const useCurrentScene = () => {
  return useSelector((s) => {
    const currentScene = Object.entries(s.sceneStates).find(
      ([, sceneState]) => sceneState?.stage === SceneStage.Entered,
    )?.[0];
    return currentScene;
  });
};

export const useIsCurrentScene = (scene: Scene) => {
  const currentScene = useCurrentScene();
  return currentScene === scene;
};

export const useSceneState = (scene: Scene) => {
  return useSelector((s) => {
    const currentScene = Object.entries(s.sceneStates).find(
      ([key]) => key === scene,
    )?.[1];
    return currentScene;
  });
};

export const useScene = (cb: EffectCallback, scene: Scene) => {
  const currentScene = useCurrentScene();

  useLayoutEffect(() => {
    if (scene !== currentScene) return noop;

    return cb();
  }, [scene, currentScene]);
};

export const useFirstScene = (cb: EffectCallback, scene: Scene) => {
  const currentScene = useCurrentScene();
  const isFirstTime = useRef(true);

  useLayoutEffect(() => {
    if (!isFirstTime.current) return noop;
    if (scene !== currentScene) return noop;

    isFirstTime.current = false;

    return cb();
  }, [scene, currentScene]);
};

export const useFirstSceneEntering = (cb: EffectCallback, scene: Scene) => {
  const sceneState = useSceneState(scene);
  const isFirst = useRef(false);

  useLayoutEffect(() => {
    if (isFirst.current || sceneState?.stage !== SceneStage.Entering) {
      return noop;
    }

    isFirst.current = true;
    return cb();
  }, [sceneState]);
};

export const useSceneEntering = (cb: EffectCallback, scene: Scene) => {
  const sceneState = useSceneState(scene);

  useLayoutEffect(() => {
    if (sceneState?.stage !== SceneStage.Entering) {
      return noop;
    }

    return cb();
  }, [sceneState?.stage]);
};

export const useSceneMounting = (cb: EffectCallback, scene: Scene) => {
  const sceneState = useSceneState(scene);

  useLayoutEffect(() => {
    if (sceneState?.stage !== SceneStage.Mounting) {
      return noop;
    }

    return cb();
  }, [sceneState?.stage]);
};

import { Environment } from '../utils/Environment';
import { AnalyticsAction } from './models/AnalyticsAction';
import { IAnalyticsProvider } from './providers/AnalyticsProvider';
import { MixpanelProvider } from './providers/MixpanelProvider';
import { MockAnalyticsProvider } from './providers/MockAnalyticsProvider';

export class Analytics {
  provider: IAnalyticsProvider;

  constructor(provider: IAnalyticsProvider) {
    this.provider = provider;
  }

  /** track event and send to Primer. */
  call(action: AnalyticsAction): boolean | void {
    return this.provider.call(action);
  }

  /** start timing event that might happen in the future. */
  time(action: AnalyticsAction): boolean | void {
    return this.provider.time(action);
  }

  /** updates analytics about which environment the SDK is running in. */
  setSdkEnvironment(env: string): void {
    this.provider.sdkEnvironment = env;
  }
}

export function createAnalytics(): Analytics {
  const provider = Environment.get('ANALYTICS_ENDPOINT')
    ? new MixpanelProvider()
    : new MockAnalyticsProvider();
  return new Analytics(provider);
}

import { useLayoutEffect } from 'preact/hooks';
import { ClickEvent } from '../analytics/constants/enums';
import { useCheckoutContext } from '../store/CheckoutContext';
import { useCheckoutStore, useSelector } from '../store/CheckoutStoreContext';
import { useFirstRender } from '../utils/hooks';

const SubmitButton = () => {
  const { viewUtils, options, context } = useCheckoutContext();

  const store = useCheckoutStore();
  const isVisible = useSelector((s) => s.submitButton.isVisible);
  const message = useSelector((s) => s.submitButton.message);
  const isLoading = useSelector((s) => s.isLoading);

  useFirstRender(() => {
    viewUtils.toggleVisibilityAnimated(
      'primer-checkout-submit-button-container',
      false,
      {
        duration: 0,
        animateHeight: true,
        autoHeight: false,
      },
    );
  });

  useLayoutEffect(() => {
    if (options.submitButton?.visible === false) {
      viewUtils.toggleVisibilityAnimated(
        'primer-checkout-submit-button-container',
        false,
        {
          duration: 0,
          animateHeight: true,
          autoHeight: false,
        },
      );
      return;
    }

    viewUtils.toggleVisibilityAnimated(
      'primer-checkout-submit-button-container',
      isVisible,
      {
        duration: 500,
        animateHeight: true,
        autoHeight: false,
      },
    );
  }, [isVisible]);

  const handleClick = () => {
    context.analytics.call({ event: ClickEvent.clickedSubmitButton });

    if (isLoading) return;
    store.triggerSubmitButtonClick();
  };

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////
  return (
    <div
      id='primer-checkout-submit-button-container'
      class='PrimerCheckout__submitButtonContainer'
    >
      <button
        type='button'
        id='primer-checkout-submit-button'
        class='PrimerCheckout__submitButton'
        disabled={isLoading}
        onClick={handleClick}
      >
        <span>{message}</span>
        <div
          id='primer-checkout-submit-button-loading'
          class='PrimerCheckout__loadingRing'
        >
          <svg class='spinner' viewBox='0 0 50 50'>
            <circle
              class='path'
              cx='25'
              cy='25'
              r='20'
              fill='none'
              stroke-width='5'
            ></circle>
          </svg>
        </div>
      </button>
    </div>
  );
};

export default SubmitButton;

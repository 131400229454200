import { Store } from 'unistore';

import {
  ICheckoutState,
  SceneStage,
  SceneState,
  BaseStore,
  NodeLike,
} from './BaseStore';

import { PaymentMethodToken, VaultListItem } from '../types';
import { TranslationUnit } from '../assets/translations/Translations';
import { formatVaultedToken } from '../checkout/formatVaultedToken';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import { Vault } from '../checkout/Vault';

export default class CheckoutStore extends BaseStore {
  private submitButtonEventListener: EventTarget;

  private vault: Vault | null;

  constructor(state: Store<ICheckoutState>) {
    super(state);

    this.submitButtonEventListener = new EventTarget();
    this.vault = null;
  }

  setVault(vault: Vault) {
    this.vault = vault;
  }

  setIsLoading(isLoading: boolean): void {
    this.produceState((draft) => {
      draft.isLoading = isLoading;
    });
  }

  ///////////////////////////////////////////
  // Scene
  ///////////////////////////////////////////

  setScene(scene: string): void {
    this.produceState((draft) => {
      let sceneState = draft.sceneStates[scene];

      if (!sceneState) {
        sceneState = { stage: SceneStage.Init };
        draft.sceneStates[scene] = sceneState;
      }

      const currentScene = Object.entries(draft.sceneStates).find(
        ([, state]) => state?.stage === SceneStage.Entered,
      )?.[1];

      if (sceneState.stage === SceneStage.Init) {
        sceneState.stage = SceneStage.Mounting;
      } else if (sceneState.stage === SceneStage.Exited) {
        sceneState.stage = SceneStage.Entering;
        if (currentScene) {
          currentScene.stage = SceneStage.Exiting;
        }
      }
    });
  }

  handleSceneEntered(scene: string): void {
    this.produceState((draft) => {
      const sceneState = draft.sceneStates[scene] as SceneState;
      sceneState.stage = SceneStage.Entered;
    });
  }

  handleSceneExited(scene: string): void {
    this.produceState((draft) => {
      const sceneState = draft.sceneStates[scene] as SceneState;
      sceneState.stage = SceneStage.Exited;
    });
  }

  handleSceneMounted(scene: string): void {
    this.produceState((draft) => {
      const currentScene = Object.entries(draft.sceneStates).find(
        ([, sceneState]) => sceneState?.stage === SceneStage.Entered,
      )?.[1];

      const sceneState = draft.sceneStates[scene] as SceneState;
      sceneState.stage = SceneStage.Entering;

      if (currentScene) {
        currentScene.stage = SceneStage.Exiting;
      }
    });
  }

  ///////////////////////////////////////////
  // Submit Button
  ///////////////////////////////////////////

  triggerSubmitButtonClick() {
    this.submitButtonEventListener.dispatchEvent(new Event('click'));
    this.setErrorMessage(null);
  }

  addSubmitButtonClickListener(
    listener: EventListener | EventListenerObject | null,
  ) {
    this.submitButtonEventListener.addEventListener('click', listener);
  }

  removeSubmitButtonClickListener(
    listener: EventListener | EventListenerObject | null,
  ) {
    this.submitButtonEventListener.removeEventListener('click', listener);
  }

  setSubmitButtonVisible(isVisible: boolean): void {
    this.produceState((draft) => {
      draft.submitButton.isVisible = isVisible;
    });
  }

  setSubmitButtonContent(content: string): void {
    this.produceState((draft) => {
      draft.submitButton.message = content;
    });
  }

  ///////////////////////////////////////////
  // Error Message
  ///////////////////////////////////////////

  setErrorMessage(content: string | null): void {
    this.produceState((draft) => {
      draft.error.message = content;
    });
  }

  ///////////////////////////////////////////
  // Payment Methods
  ///////////////////////////////////////////

  addAPM(apm: NodeLike): void {
    this.produceState((draft) => {
      draft.apms.items.push(apm);
    });
  }

  setPaymentMethods(paymentMethods: Record<string, BasePaymentMethod>) {
    this.setState({ paymentMethods });

    const apms = Object.keys(paymentMethods)
      .filter((name) => !/^(?:card|directDebit)$/.test(name))
      .map((name) => ({ id: `primer-checkout-apm-${name}` }))
      .filter(Boolean);

    apms.forEach((apm) => {
      this.addAPM(apm);
    });
  }

  ///////////////////////////////////////////
  // Vault
  ///////////////////////////////////////////
  selectFirstVault(): void {
    if (this.hasVault) {
      this.produceState((draft) => {
        draft.vault.selected = draft.vault.items[0].id;
      });
    }
  }

  addVault(vault: PaymentMethodToken): Nullable<VaultListItem> {
    const formatted = formatVaultedToken(vault);

    if (formatted == null) {
      return null;
    }

    this.produceState((draft) => {
      draft.isLoading = true;
      draft.tokens.push(vault);
      draft.vault.items.push(formatted);
    });

    return formatted;
  }

  selectVault(id: Nullable<string>): void {
    this.produceState((draft) => {
      draft.vault.selected = id;
    });
  }

  async removeVault(id: string): Promise<void> {
    await this.vault?.delete(id);

    this.produceState((draft) => {
      if (draft.vault.selected === id) {
        draft.vault.selected = null;
      }

      draft.vault.items = draft.vault.items.filter((elm) => elm.id !== id);
      draft.tokens = draft.tokens.filter((elm) => elm.token !== id);
    });
  }

  ///////////////////////////////////////////
  // Small Print
  ///////////////////////////////////////////

  setSmallPrintMessage(message: Nullable<string>): void {
    this.produceState((draft) => {
      draft.smallPrint.message = message;
    });
  }

  ///////////////////////////////////////////
  // Getters
  ///////////////////////////////////////////

  get hasAPMs(): boolean {
    return false;
  }

  get selectedAPM(): Nullable<string> {
    return this.getState().apms.selected;
  }

  get hasVault(): boolean {
    return this.getState().vault.items.length > 0;
  }

  get hasCard(): boolean {
    return this.getState().options.hasCard;
  }

  get hasDirectDebit(): boolean {
    return this.getState().options.hasDirectDebit;
  }

  get selectedVault(): Nullable<string> {
    return this.getState().vault.selected;
  }

  get savePaymentMethodVisible(): boolean {
    return this.getState().options.showSavePaymentMethod;
  }

  getSelectedVaultToken(): Nullable<PaymentMethodToken> {
    return (
      this.getState().tokens.find(
        (elm) => elm.token === this.getState().vault.selected,
      ) || null
    );
  }

  getAllAPMs(): NodeLike[] {
    return this.getState().apms.items;
  }

  getAllVaulted(): VaultListItem[] {
    return this.getState().vault.items;
  }

  getTranslations(): TranslationUnit {
    return this.getState().translation as Record<string, string>;
  }

  hasVaultedToken(token: PaymentMethodToken): boolean {
    return !!this.getState().tokens.find(
      (elm) => elm.analyticsId === token.analyticsId,
    );
  }
}

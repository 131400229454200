import {
  CheckoutOptions,
  ThreeDSVerification,
  ThreeDSVerificationOptions,
} from '../types';
import ThreeDSecure from '../three-d-secure/ThreeDSecure';
import { PaymentInstrumentType } from '../enums/Tokens';
import { ProgressEvent, ThreeDSecureStatus } from '../enums/Tokenization';
import { ElementID, Scene } from '../enums/Checkout';
import { ClientContext } from '../core/ClientContextFactory';
import { createTokenizeSuccessCallback } from './createTokenizeSuccessCallback';

import { IViewUtils } from './ui/types';
import { mergeTokenWithSynthetic3DSError } from './mergeTokenWithSynthetic3DSError';
import { ApiEvent } from '../analytics/constants/enums';

type TokenizationHandlers = Pick<
  CheckoutOptions,
  | 'onTokenizeStart'
  | 'onTokenizeError'
  | 'onTokenizeSuccess'
  | 'onTokenizeProgress'
>;

export const CheckoutTokenizationHandlers = {
  create(
    context: ClientContext,
    viewUtils: IViewUtils,
    options: CheckoutOptions,
  ): TokenizationHandlers {
    const threeDS = new ThreeDSecure({ context });
    threeDS.setup({
      container: `#${ElementID.THREE_DS_MODAL}`,
      onChallengeStart: () =>
        viewUtils.toggleVisibilityAnimated(Scene.THREE_DS, true, {
          duration: 500,
        }),
      onChallengeEnd: () =>
        viewUtils.toggleVisibilityAnimated(Scene.THREE_DS, false, {
          duration: 500,
        }),
    });

    return {
      onTokenizeProgress(ev) {
        switch (ev.type) {
          case ProgressEvent.TOKENIZE_STARTED:
            viewUtils.store.setIsLoading(true);
            break;
          case ProgressEvent.TOKENIZE_ERROR:
            viewUtils.store.setIsLoading(false);
            break;
          default:
            break;
        }
      },
      onTokenizeStart: options.onTokenizeStart,
      onTokenizeError: (error) => {
        viewUtils.store.setErrorMessage(error.message);
        context.analytics.call({ event: ApiEvent.tokenizationError });
        options.onTokenizeError?.(error);
      },
      async onTokenizeSuccess(data) {
        const onSuccess = createTokenizeSuccessCallback(
          options.onTokenizeSuccess,
          viewUtils,
        );

        if (data.paymentInstrumentType !== PaymentInstrumentType.CARD) {
          onSuccess(data);
          context.analytics.call({
            event: ApiEvent.completedCheckout,
            data: {
              paymentMethod: data.paymentInstrumentType,
            },
          });
          return;
        }

        if (!options.threeDSecure || !context.session.threeDSecureToken) {
          onSuccess(data);
          context.analytics.call({
            event: ApiEvent.completedCheckout,
            data: {
              paymentMethod: data.paymentInstrumentType,
            },
          });
          return;
        }

        const verifyOptions: Partial<ThreeDSVerificationOptions> =
          options.threeDSecure;

        const verification: ThreeDSVerification = await threeDS.verify({
          token: data.token,
          ...verifyOptions,
        });

        if (verification.error) {
          context.analytics.call({ event: ApiEvent.threeDSecureError });
          onSuccess(mergeTokenWithSynthetic3DSError(data, verification.error));
          return;
        }

        if (verification.status === ThreeDSecureStatus.FAILED) {
          context.analytics.call({ event: ApiEvent.threeDSecureFailed });
        }

        if (verification.status === ThreeDSecureStatus.SKIPPED) {
          context.analytics.call({ event: ApiEvent.threeDSecureSkipped });
        }

        if (verification.status === ThreeDSecureStatus.SUCCESS) {
          context.analytics.call({ event: ApiEvent.threeDSecureSuccess });
        }

        context.analytics.call({
          event: ApiEvent.completedCheckout,
          data: {
            paymentMethod: data.paymentInstrumentType,
          },
        });
        // TODO: verification.data will be available following next deploy
        onSuccess(verification.data || data);
      },
    };
  },
};

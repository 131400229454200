import { ProgressEvent } from '../enums/Tokenization';

export default class ProgressNotifier {
  constructor(callbacks) {
    this.callbacks = callbacks;
  }

  emit(type, data) {
    this.callbacks.onTokenizeProgress({ type, data });

    switch (type) {
      case ProgressEvent.TOKENIZE_STARTED:
        this.callbacks.onTokenizeStart();
        break;

      case ProgressEvent.TOKENIZE_ERROR:
        this.callbacks.onTokenizeEnd();
        this.callbacks.onTokenizeError(data);
        break;

      case ProgressEvent.TOKENIZE_SUCCESS:
        this.callbacks.onTokenizeEnd();
        this.callbacks.onTokenizeSuccess(data);
        break;

      default:
        break;
    }
  }
}

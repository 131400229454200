import { PaymentMethodType } from '../enums/Tokens';
import CreditCard from './credit-card/CreditCard';
import { PayPal } from './paypal/PayPal';
import { GooglePay } from './google-pay/GooglePay';
import { GoCardless } from './go-cardless/GoCardless';
import ApplePay from './apple-pay/ApplePay';
import PaymentMethodContext from '../core/PaymentMethodContext';
import normalizeOptions from './normalizeOptions';
import window from '../utils/window';
import { createStyleManager } from '../checkout/ui/StyleManager';

export const definitions = [
  {
    type: PaymentMethodType.PAYMENT_CARD,
    label: 'Card',
    PaymentMethod: CreditCard,
    key: 'card',
    canVault: true,
  },
  {
    type: PaymentMethodType.APPLE_PAY,
    label: 'Apple Pay',
    PaymentMethod: ApplePay,
    key: 'applePay',
  },
  {
    type: PaymentMethodType.GOOGLE_PAY,
    label: 'Google Pay',
    PaymentMethod: GooglePay,
    key: 'googlePay',
  },
  {
    type: PaymentMethodType.PAYPAL,
    label: 'PayPal',
    PaymentMethod: PayPal,
    key: 'paypal',
  },
  {
    type: PaymentMethodType.GO_CARDLESS,
    label: 'Go Cardless',
    PaymentMethod: GoCardless,
    key: 'directDebit',
  },
];

export const PaymentMethods = {
  async create(context, opts, viewUtils) {
    const options = normalizeOptions(opts);

    const paymentMethods = PaymentMethodContext.create(context, options);

    const ops = definitions.map(async (definition) => {
      const { type, key, label, canVault = false } = definition;

      const inputOptions = options[key] || {};

      if (options.vaultOnly && !canVault) {
        // TODO: return this info server-side. This will do for now
        return null;
      }

      const remoteConfig = context.session.paymentMethods.find(
        (elm) => elm.type === type,
      );

      if (!remoteConfig) {
        if (options.logWarnings) {
          window.console.warn(
            `Options for "${label}" were provided but no matching configuration was found for your Primer account.`,
          );
        }
        return null;
      }

      const paymentMethod = new definition.PaymentMethod(
        paymentMethods,
        inputOptions,
        remoteConfig,
        viewUtils ? viewUtils.styleManager : createStyleManager(),
      );

      const loaded = await paymentMethod.setupAndValidate();

      if (!loaded) {
        return null;
      }

      if (options.mountImmediately) {
        await paymentMethod.mount();
      }

      return { [key]: paymentMethod };
    });

    const results = await Promise.all(ops);

    return Object.assign({}, ...results);
  },
};

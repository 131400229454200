import { PayPal } from './PayPal';
import { parseAmountValue } from '../../utils/parseAmountValue';
import { PayPalMonetaryAmount } from './types';
import { PurchaseInformation } from '../../types';
import { ErrorCode, PrimerClientError } from '../../errors';

export interface PayPalStrategy {
  methodName: string;
  createOrder: (
    instance: PayPal,
  ) => (data: unknown, actions: unknown) => Promise<Nullable<string>>;
  createToken: (instance: PayPal) => (approval: unknown) => Promise<unknown>;
}

interface OrderActions {
  order: {
    create(data: unknown): Promise<string>;
  };
}

interface ApprovedOrder {
  orderID: string;
}

interface ApprovedBillingAgreement {
  billingToken: string;
}

interface BillingAgreementToken {
  tokenId: string;
}

export const OrderStrategy: PayPalStrategy = {
  methodName: 'createOrder',
  createOrder: (instance: PayPal) => (data: unknown, actions: OrderActions) => {
    const { totalAmount } = (instance.context
      .purchaseInfo as () => PurchaseInformation)();

    return actions.order.create({
      purchase_units: [{ amount: normalizeAmount(totalAmount) }],
    });
  },

  createToken: () => (approval: ApprovedOrder) => {
    return Promise.resolve({
      paymentInstrument: {
        paypal_order_id: approval.orderID,
      },
    });
  },
};

export const VaultStrategy: PayPalStrategy = {
  methodName: 'createBillingAgreement',
  createOrder: (instance: PayPal) => async () => {
    const { data, error } = await instance.context.api.post(
      '/paypal/billing-agreements/create-agreement',
      {
        paymentMethodConfigId: instance.remoteConfig.id,
      },
    );

    if (error) {
      window.console.log('Failed to create billing agreement', error);
      return null;
    }

    return (data as BillingAgreementToken).tokenId;
  },
  createToken: (instance: PayPal) => async (
    approval: ApprovedBillingAgreement,
  ) => {
    const { data, error } = await instance.context.api.post(
      '/paypal/billing-agreements/confirm-agreement',
      {
        paymentMethodConfigId: instance.remoteConfig.id,
        tokenId: approval.billingToken,
      },
    );

    if (error) {
      throw PrimerClientError.fromErrorCode(
        ErrorCode.PRIMER_SERVER_ERROR,
        error,
      );
    }

    const agreement = data as Record<string, unknown>;

    return {
      tokenType: 'MULTI_USE',
      paymentFlow: 'VAULT',
      paymentInstrument: {
        paypalBillingAgreementId: agreement.billingAgreementId,
        shippingAddress: agreement.shippingAddress,
        externalPayerInfo: agreement.externalPayerInfo,
      },
    };
  },
};

function normalizeAmount({ value, currency }): PayPalMonetaryAmount {
  const amount: PayPalMonetaryAmount = {
    value: parseAmountValue(value).asString(),
  };

  if (currency) {
    amount.currency_code = currency.toUpperCase();
  }

  return amount;
}

import { BaseModule, IModuleLoader, ModuleDescriptor } from './types';
import { ScriptLoader } from '../ScriptLoader';

enum ModuleStatus {
  UNINITIALIZED,
  LOADING,
  READY,
  ERROR,
}

interface ModuleLoaderOptions {
  scriptLoader: ScriptLoader;
}

interface ModuleKey {
  entryPoint: string;
  scope: string;
  path: string;
}

export class ModuleLoader implements IModuleLoader {
  private moduleDescriptors: Array<ModuleDescriptor>;

  private scriptLoader: ScriptLoader;

  constructor(options: ModuleLoaderOptions) {
    this.scriptLoader = options.scriptLoader;
    this.moduleDescriptors = [];
  }

  public async loadModule(key: ModuleKey): Promise<ModuleDescriptor> {
    const { entryPoint, scope, path } = key;

    let moduleDescriptor = this.getModuleDescriptor(key);
    if (moduleDescriptor) {
      if (moduleDescriptor.status === ModuleStatus.READY) {
        return moduleDescriptor;
      }
      if (moduleDescriptor.status === ModuleStatus.LOADING) {
        return new Promise((resolve) =>
          moduleDescriptor?.callbacks.push(() =>
            resolve(moduleDescriptor as ModuleDescriptor),
          ),
        );
      }
    }

    moduleDescriptor = {
      key,
      status: ModuleStatus.LOADING,
      callbacks: [],
      module: null,
    };
    this.moduleDescriptors.push(moduleDescriptor);

    const isScriptLoaded = await this.scriptLoader.load(entryPoint);
    if (!isScriptLoaded) {
      moduleDescriptor.status = ModuleStatus.ERROR;
      throw new Error('Script not loaded');
    }

    const Module = await this.loadModuleFederation(scope, path);
    if (!Module) {
      moduleDescriptor.status = ModuleStatus.ERROR;
      throw new Error('Module not loaded');
    }

    moduleDescriptor.status = ModuleStatus.READY;
    moduleDescriptor.module = Module;
    moduleDescriptor.callbacks.forEach((callback) =>
      callback(moduleDescriptor as ModuleDescriptor),
    );
    moduleDescriptor.callbacks = [];

    return moduleDescriptor;
  }

  private getModuleDescriptor({ entryPoint, scope, path }: ModuleKey) {
    return this.moduleDescriptors.find(
      (module) =>
        module.key.entryPoint === entryPoint &&
        module.key.scope === scope &&
        module.key.path === path,
    );
  }

  private async loadModuleFederation(
    scope: string,
    path: string,
  ): Promise<BaseModule> {
    await __webpack_init_sharing__('default');
    const container = window[scope];
    await container.init(__webpack_share_scopes__.default);
    const factory = await container.get(path);
    const Module = factory();
    return Module;
  }
}

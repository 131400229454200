import CardinalCommerceProvider from './CardinalCommerceProvider';
import { ClientContextFactory } from '../core/ClientContextFactory';
import { ApiEvent } from '../analytics/constants/enums';

export default class ThreeDSecure {
  constructor({ context = null, options = null }) {
    this.options = options;
    this.context = context;

    this.provider = null;
  }

  async getContext() {
    const context =
      this.context || (await ClientContextFactory.create(this.options));
    return context;
  }

  async getProvider() {
    if (!this.provider) {
      const context = await this.getContext();
      this.provider = new CardinalCommerceProvider(context);
    }

    return this.provider;
  }

  async setup(options) {
    const provider = await this.getProvider();
    provider.setup(options);
  }

  async verify(options) {
    const provider = await this.getProvider();
    const context = await this.getContext();

    // analytics, track 3ds event triggered.
    context.analytics.call({ event: ApiEvent.triggered3ds });

    return provider.verify(options);
  }
}

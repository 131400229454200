import { uuid } from '../utils/uuid';
import window from '../utils/window';
import * as hash from '../utils/location-hash';
import { IFrameMessageBus } from './IFrameMessageBus';
import { locateElement } from '../utils/dom';
import { noop } from '../utils/noop';
import { addEventListener } from '../utils/addEventListener';
import { prepend } from '../utils/Node.prepend';
import { IFrameEventType } from './IFrameEventType';
import { HTMLElementEventType } from '../enums/HTMLElementEventType';

type IFramePlacement = 'append' | 'prepend';

interface IFrameFactoryOptions {
  messageBus: IFrameMessageBus;
  assetsUrl: string;
}

interface CreateIFrameOptions {
  filename: string;
  container: string | Element;
  placement?: IFramePlacement;
  payload?: unknown;
  meta: {
    name: string;
    id?: string;
    ariaLabel?: string;
  };
  style?: Record<string, string>;
  onReady?: () => void;
}

const defaultIframeStyle = {
  border: 'none',
  float: 'left',
  width: '100%',
  height: '100%',
};

export class IFrameFactory {
  private id: string;

  private messageBus: IFrameMessageBus;

  private assetsUrl: string;

  constructor({ messageBus, assetsUrl }: IFrameFactoryOptions) {
    this.id = uuid();
    this.messageBus = messageBus;
    this.assetsUrl = assetsUrl;
  }

  create({
    filename,
    container,
    meta,
    payload = {},
    placement = 'append',
    style = defaultIframeStyle,
    onReady = noop,
  }: CreateIFrameOptions): HTMLIFrameElement | null {
    const iframe = window.document.createElement('iframe');
    const info = hash.encode({ sessionId: this.id, name: meta.name });

    Object.entries(style).forEach(([key, value]) => {
      iframe.style[key] = value;
    });

    iframe.style.visibility = 'hidden';

    addEventListener(iframe, HTMLElementEventType.LOAD, () => {
      iframe.style.visibility = 'visible';
    });

    addEventListener(iframe, HTMLElementEventType.LOAD, () => {
      this.messageBus.publish(meta.name, {
        type: IFrameEventType.MOUNT,
        meta,
        payload,
      });
      onReady();
    });

    iframe.setAttribute('title', meta.ariaLabel || '');
    iframe.setAttribute('data-primer-frame', meta.name);
    iframe.setAttribute('frameBorder', '0');
    iframe.setAttribute('id', meta.id || uuid());
    iframe.setAttribute('src', `${this.assetsUrl}/${filename}#${info}`);

    const parent = locateElement(container);

    if (!parent) {
      window.console.error(
        `Attempted to mount element inside container ${container} but it could not be found on the page.`,
      );
      return null;
    }

    if (placement === 'prepend') {
      prepend(parent, iframe);
    } else {
      parent.appendChild(iframe);
    }

    this.messageBus.add(iframe);

    return iframe;
  }
}

import { CheckoutStyle } from '../../../styles';

const primaryTextColor = '#262626';
const placeholderColor = '#bfbfbf';
const primaryColor = '#3447f4';
const borderColor = '#e0e0e0';
const errorColor = 'indianred';
const disabledColor = '#bdbdbd';
const hoverBgColor = '#eaf1ff';

export const defaultCheckoutStyle: CheckoutStyle = {
  loadingScreen: {
    color: primaryColor,
  },

  input: {
    base: {
      color: primaryTextColor,
      background: 'white',
      borderStyle: 'solid',
      borderColor,
      borderWidth: 1,
      borderRadius: 2,
      fontFamily: 'Avenir',
      fontWeight: '400',

      placeholder: {
        color: placeholderColor,
      },
      focus: {
        borderColor: primaryColor,
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.14)',
      },
    },
    error: {
      borderColor: errorColor,
    },
  },

  inputErrorText: {
    fontWeight: '400',
  },

  submitButton: {
    base: {
      background: primaryColor,
      borderRadius: 6,
      boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.25)',

      color: 'white',
      fontSize: '16px',
    },

    loading: {
      background: disabledColor,
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    },
  },

  savedPaymentMethodButton: {
    base: {
      background: 'white',
      borderRadius: 2,
      boxShadow: '0px 0px rgba(0, 0, 0, 0)',
      borderColor,
      borderWidth: 1,
      borderStyle: 'solid',

      hover: {
        background: hoverBgColor,
      },

      focus: {
        background: hoverBgColor,
      },
    },

    selected: {
      color: primaryColor,
      background: 'white',
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.14)',
      borderColor: primaryColor,

      hover: {
        background: 'white',
      },

      focus: {
        background: hoverBgColor,
      },
    },
  },
};

import { render, h, FunctionalComponent } from 'preact';
import bem from 'easy-bem';

import { locateElement } from '../utils/dom';
import { PaymentMethods } from '../payment-methods/PaymentMethods';
import { CheckoutPaymentMethodConfig } from './CheckoutPaymentMethodConfig';
import { CheckoutTokenizationHandlers } from './CheckoutTokenizationHandlers';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import { CheckoutOptions, PrimerCheckout } from '../types';
import { Vault } from './Vault';
import { getAttribute, setValue } from './DomUtilities';
import { ElementID, Scene } from '../enums/Checkout';
import { Translations } from '../assets/translations/Translations';
import { IFrameEventType } from '../core/IFrameEventType';
import { IFrameMessagePayload } from '../core/IFrameMessage';
import { ClientContext } from '../core/ClientContextFactory';
import { PaymentMethodType } from '../enums/Tokens';

import { createCheckoutViewUtils } from './ui/ViewUtils';

import CheckoutRoot from '../scenes/CheckoutRoot';
import {
  createStore,
  Provider as StoreProvider,
} from '../store/CheckoutStoreContext';
import { Provider as CheckoutProvider } from '../store/CheckoutContext';
import { SceneTransition } from './SceneTransition';
import { Environment } from '../utils/Environment';

export class Checkout {
  static async create(
    context: ClientContext,
    options: CheckoutOptions,
  ): Promise<PrimerCheckout> {
    const parent = locateElement(options.container);

    if (parent == null) {
      return onConfigError(
        `Attempted to mount checkout UI at "${options.container}" but the element could not be found`,
      );
    }

    const root = document.createElement('div');
    parent.prepend(root);

    ///////////////////////////////////////////
    // Render
    ///////////////////////////////////////////
    const store = createStore();
    const viewUtils = createCheckoutViewUtils(store, options);
    const transitions = new SceneTransition();

    async function validateCardForm() {
      let valid = false;

      const token = store.getSelectedVaultToken();

      if (token) {
        valid = true;
      } else {
        const formValidation = await paymentMethods.card.validate();
        let uxValid = true;

        if (cardholderNameRequired) {
          const value = getAttribute(ElementID.CARDHOLDER_NAME_INPUT, 'value');
          const error = value ? null : translation.required;
          viewUtils.fieldUtils.setErrorState(ElementID.CARDHOLDER_NAME, error);
          uxValid = !!value;
        }

        valid = formValidation.valid && uxValid;
      }

      return {
        valid,
      };
    }

    async function tokenizeCard() {
      const token = store.getSelectedVaultToken();

      const { valid } = await validateCardForm();

      if (!valid) {
        return;
      }

      if (token) {
        store.setIsLoading(true);
        config.onTokenizeSuccess(token);
        return;
      }

      await paymentMethods.card.tokenize();
    }

    const checkout = {
      validate: validateCardForm,
      tokenize: tokenizeCard,
    };

    render(
      <CheckoutProvider
        value={{
          options,
          checkout,
          viewUtils,
          context,
          transitions,
          className: bem('PrimerCheckout'),
        }}
      >
        <StoreProvider value={store}>
          <CheckoutRoot />
        </StoreProvider>
      </CheckoutProvider>,
      root,
    );

    ///////////////////////////////////////////
    // State
    ///////////////////////////////////////////
    const translation = Translations.get(options.locale);
    const cardOptions = options.card || {};
    const { cardholderNameRequired = true } = cardOptions;

    store.produceState((draft) => {
      draft.translation = translation;
      draft.options = {
        showSavePaymentMethod: false,
        hasCard: !!context.session.paymentMethods.find(
          (pm) => pm.type === PaymentMethodType.PAYMENT_CARD,
        ),
        hasDirectDebit: !!context.session.paymentMethods.find(
          (pm) => pm.type === PaymentMethodType.GO_CARDLESS,
        ),
      };
    });

    ///////////////////////////////////////////
    // Vault
    ///////////////////////////////////////////
    const vault = new Vault(context);
    store.setVault(vault);

    const vaulted = await vault.fetch(options.customerId);

    vaulted.forEach((pm) => {
      store.addVault(pm);
    });

    const config = {
      ...CheckoutPaymentMethodConfig.create(store, viewUtils, options),
      ...CheckoutTokenizationHandlers.create(context, viewUtils, options),
    };

    const paymentMethods: Record<
      string,
      BasePaymentMethod
    > = await PaymentMethods.create(context, config, viewUtils);

    store.setPaymentMethods(paymentMethods);

    context.messageBus.on(
      IFrameEventType.CARDHOLDER_NAME,
      (e: IFrameMessagePayload<string>) => {
        setValue(ElementID.CARDHOLDER_NAME_INPUT, e.payload);
      },
    );

    store.selectFirstVault();

    store.setScene(Scene.CHOOSE_PAYMENT_METHOD);
    store.setIsLoading(false);

    if (Environment.get('PRIMER_BUILD_INTEGRATION_BUILDER', false)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
      const { CardProxy } = require('./CardProxy');

      const cardProxy = new CardProxy(context, config, paymentMethods.card);
      // @ts-expect-error for integration builder
      checkout.card = cardProxy;
    }

    return checkout;
  }
}

function onConfigError(message: string): PrimerCheckout {
  console.error(message);
  return {
    tokenize: () => Promise.resolve(),
    validate: () => Promise.resolve({ valid: false }),
  };
}

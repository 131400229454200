import {
  Action,
  BoundAction,
  Listener,
  Store as IStore,
  Unsubscribe,
} from 'unistore';
import produce, { Draft } from 'immer';

import { PaymentMethodToken, VaultListItem } from '../types';
import { Scene } from '../enums/Checkout';
import { TranslationUnit } from '../assets/translations/Translations';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';

export interface NodeLike {
  id: string;
}

export enum SceneStage {
  Init = 'init',
  Mounting = 'mounting',
  Entering = 'entering',
  Entered = 'entered',
  Exiting = 'exiting',
  Exited = 'exited',
}

export type SceneState = {
  stage: SceneStage;
};

export interface ICheckoutState {
  isLoading: boolean;
  //
  translation: TranslationUnit | null;
  tokens: PaymentMethodToken[];
  apms: {
    selected: Nullable<string>;
    items: NodeLike[];
  };
  vault: {
    selected: Nullable<string>;
    items: VaultListItem[];
  };
  options: {
    showSavePaymentMethod: boolean;
    hasCard: boolean;
    hasDirectDebit: boolean;
  };

  ///////////////////////////////////////////
  // Current scene
  ///////////////////////////////////////////
  sceneStates: Partial<Record<Scene, SceneState>>;

  ///////////////////////////////////////////
  // Vaulted payment methods
  ///////////////////////////////////////////
  selectedVaultedPaymentMethod?: string;

  ///////////////////////////////////////////
  // Error
  ///////////////////////////////////////////
  error: {
    message: string | null;
  };

  ///////////////////////////////////////////
  // SmallPrint
  ///////////////////////////////////////////

  smallPrint: {
    message: string | null;
  };

  ///////////////////////////////////////////
  // Payment Methods
  ///////////////////////////////////////////
  paymentMethods: Record<string, BasePaymentMethod>;

  ///////////////////////////////////////////
  // Submit Button
  ///////////////////////////////////////////
  submitButton: {
    isVisible: boolean;
    message: string;
  };

  ///////////////////////////////////////////
  // Direct Debit
  ///////////////////////////////////////////

  directDebit: {
    customerName: string | null;
    customerAddress: string | null;
    customerIban: string | null;
  };
}

export class BaseStore<K = ICheckoutState> implements IStore<K> {
  readonly state: IStore<K>;

  constructor(state: IStore<K>) {
    this.state = state;
  }

  action(action: Action<K>): BoundAction {
    return this.state.action(action);
  }

  setState<U extends keyof K>(
    update: Pick<K, U>,
    overwrite?: boolean,
    action?: Action<K>,
  ): void {
    this.state.setState(update, overwrite, action);
  }

  produceState(recipe: (draft: Draft<K>) => Pick<K, never> | void): void {
    this.state.setState(produce(this.getState(), recipe));
  }

  subscribe(f: Listener<K>): Unsubscribe {
    return this.state.subscribe(f);
  }

  unsubscribe(f: Listener<K>): void {
    return this.state.unsubscribe(f);
  }

  getState(): K {
    return this.state.getState();
  }
}

import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import { ElementID } from '../enums/Checkout';
import { useCheckoutContext } from '../store/CheckoutContext';
import { useSelector } from '../store/CheckoutStoreContext';
import { useFirstRender, useIsFirstRender } from '../utils/hooks';

const DURATION = 500;

const SmallPrint = () => {
  const { viewUtils } = useCheckoutContext();
  const message = useSelector((s) => s.smallPrint.message);
  const [content, setContent] = useState<string | null>(null);
  const isVisible = useRef(false);
  const isFirstRender = useIsFirstRender();

  const toggleVisibility = (show: boolean, animated = true): void => {
    isVisible.current = false;

    viewUtils.toggleVisibilityAnimated(ElementID.DD_MANDATE_SMALL_PRINT, show, {
      duration: animated ? DURATION : 0,
      animateHeight: true,
      autoHeight: true,
      onFinish: () => {
        if (!show) {
          setContent(null);
        }
      },
    });
  };

  useFirstRender(() => {
    toggleVisibility(false, false);
  });

  useLayoutEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (!message) {
      toggleVisibility(false, true);
    } else {
      setContent(message);
    }
  }, [message]);

  useLayoutEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (content) {
      toggleVisibility(true, true);
    }
  }, [content]);

  return (
    <p
      id='primer-checkout-small-print'
      class='PrimerCheckout--centered PrimerCheckout__label PrimerCheckout__label--muted PrimerCheckout__label--size-sm'
    >
      {content}
    </p>
  );
};

export default SmallPrint;

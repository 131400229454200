import { PaymentMethodToken } from '../types';
import { IViewUtils } from './ui/types';

interface TokenizeSuccessCallback<T = void> {
  (data: PaymentMethodToken): T;
}

export function createTokenizeSuccessCallback(
  fn: TokenizeSuccessCallback<void | Promise<void>>,
  viewUtils: IViewUtils,
): TokenizeSuccessCallback {
  return (data: PaymentMethodToken) => {
    const promise = fn(data);

    if (promise && promise.then) {
      promise
        .then(() => viewUtils.store.setIsLoading(false))
        .catch((error: Error) => {
          viewUtils.store.setErrorMessage(error.message);
          viewUtils.store.setIsLoading(false);
        });
    } else {
      viewUtils.store.setIsLoading(false);
    }
  };
}

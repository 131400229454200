import { FunctionalComponent } from 'preact';
import { useEffect, useLayoutEffect } from 'preact/hooks';
import { Icons } from '../../assets/icons/Icons';
import { useSelector } from '../../store/CheckoutStoreContext';
import { useWaitForComponent } from '../../utils/SceneLoader/WaitForComponents';
import CreditCard from './CreditCard';

const CreditCardForm: FunctionalComponent = () => {
  const labels = useSelector((s) => s.translation);
  const { wait, ready } = useWaitForComponent();
  const paymentMethods = useSelector((s) => s.paymentMethods);
  const showSavePaymentMethod = useSelector(
    (s) => s.options.showSavePaymentMethod,
  );

  const card = paymentMethods.card as CreditCard;

  useLayoutEffect(() => {
    wait('card');
  }, []);

  useEffect(() => {
    if (!card) return;
    (async () => {
      await card.mount();
      ready('card');
    })();
  }, [card]);

  return (
    <div
      id='primer-checkout-card-form'
      class='PrimerCheckout__sceneElement PrimerCheckout__cardForm'
    >
      <div
        id='primer-checkout-card-number-field'
        class='PrimerCheckout__formField'
      >
        <label class='PrimerCheckout__label'>{labels?.cardNumber}</label>
        <div
          id='primer-checkout-card-number-input'
          class='PrimerCheckout__formInput PrimerCheckout__formInput--cardNumber'
        >
          <div
            id='primer-checkout-card-types'
            class='PrimerCheckout__cardTypes'
          >
            <img
              src={Icons.visa}
              id='primer-checkout-card-type-visa'
              class='PrimerCheckout__cardType PrimerCheckout__cardType--visa'
              alt='Visa Logo'
            />
            <img
              src={Icons.mastercard}
              id='primer-checkout-card-type-mastercard'
              class='PrimerCheckout__cardType PrimerCheckout__cardType--mastercard'
              alt='Mastercard Logo'
            />
            <img
              src={Icons.americanExpress}
              id='primer-checkout-card-type-american-express'
              class='PrimerCheckout__cardType PrimerCheckout__cardType--american-express'
              alt='American Express Logo'
            />
            <img
              src={Icons.jcb}
              id='primer-checkout-card-type-jcb'
              class='PrimerCheckout__cardType PrimerCheckout__cardType--jcb'
              alt='JCB Logo'
            />
            <img
              src={Icons.discover}
              id='primer-checkout-card-type-discover'
              class='PrimerCheckout__cardType PrimerCheckout__cardType--discover'
              alt='Discover Logo'
            />
            <img
              src={Icons.genericCard}
              id='primer-checkout-card-type-generic-card'
              class='PrimerCheckout__cardType PrimerCheckout__cardType--generic-card'
              alt='Card Logo'
            />
          </div>
        </div>

        <div
          id='primer-checkout-card-number-error'
          class='PrimerCheckout__helperText PrimerCheckout__helperText--error'
        ></div>
      </div>
      <div class='PrimerCheckout__formField PrimerCheckout__formField--dual'>
        <div
          id='primer-checkout-card-expiry-field'
          class='PrimerCheckout__formField'
        >
          <label class='PrimerCheckout__label'>{labels?.cardExpiry}</label>
          <div
            id='primer-checkout-card-expiry-input'
            class='PrimerCheckout__formInput'
          ></div>
          <div
            id='primer-checkout-card-expiry-error'
            class='PrimerCheckout__helperText PrimerCheckout__helperText--error'
          ></div>
        </div>
        <div
          id='primer-checkout-card-cvv-field'
          class='PrimerCheckout__formField'
        >
          <label class='PrimerCheckout__label'>{labels?.cardCVV}</label>

          <div
            id='primer-checkout-card-cvv-input'
            class='PrimerCheckout__formInput'
          ></div>
          <div
            id='primer-checkout-card-cvv-error'
            class='PrimerCheckout__helperText PrimerCheckout__helperText--error'
          ></div>
        </div>
      </div>
      <div
        id='primer-checkout-cardholder-name-field'
        class='PrimerCheckout__formField'
      >
        <label
          class='PrimerCheckout__label'
          for='primer-checkout-cardholder-name-input'
        >
          {labels?.cardholderName}
        </label>
        <input
          id='primer-checkout-cardholder-name-input'
          class='PrimerCheckout__formInput'
          type='text'
          placeholder={labels?.cardholderNamePlaceholder}
          autocomplete='cc-name'
        />
        <div
          id='primer-checkout-cardholder-name-error'
          class='PrimerCheckout__helperText PrimerCheckout__helperText--error'
        ></div>
      </div>
      {showSavePaymentMethod && (
        <div
          id='primer-checkout-save-payment-method-field'
          class='PrimerCheckout__formField PrimerCheckout__formField--orientation-horizontal'
        >
          <input
            id='primer-checkout-save-payment-method-input'
            type='checkbox'
            class='PrimerCheckout__checkbox'
          />
          <label
            for='primer-checkout-save-payment-method-input'
            class='PrimerCheckout__label PrimerCheckout__label--size-sm'
          >
            {labels?.savePaymentMethod}
          </label>
        </div>
      )}
    </div>
  );
};

export default CreditCardForm;

import { funcify } from '../utils/funcify';
import { noop } from '../utils/noop';

export default function normalizeOptions(options) {
  const purchaseInfo = options.purchaseInfo
    ? funcify(options.purchaseInfo)
    : null;

  return {
    ...options,
    purchaseInfo,
    logWarnings:
      typeof options.logWarnings === 'boolean' ? options.logWarnings : false,
    mountImmediately:
      typeof options.mountImmediately === 'boolean'
        ? options.mountImmediately
        : true,
    vaultOnly: options.vaultOnly || false,
    onTokenizeStart: options.onTokenizeStart || noop,
    onTokenizeProgress: options.onTokenizeProgress || noop,
    onTokenizeSuccess: options.onTokenizeSuccess || noop,
    onTokenizeError: options.onTokenizeError || noop,
    onTokenizeEnd: options.onTokenizeEnd || noop,
  };
}

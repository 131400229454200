import { ElementOrID } from '../../types';
import { resolveElement } from '../../utils/resolveElement';
import { toggleClass } from '../../utils/toggleClass';
import { toggleVisibility } from '../DomUtilities';
import {
  toggleVisibilityAnimatedFn,
  toggleVisibilityAnimatedOptions,
} from './types';

export function createToggleVisibilityAnimated(): toggleVisibilityAnimatedFn {
  const timeoutFunctions = {};
  const visible = {};

  return function toggleVisibilityAnimated(
    elementOrId: ElementOrID,
    show: boolean,
    {
      id,
      duration = 0,
      classPrefix = 'PrimerCheckout',
      animateHeight,
      autoHeight,
      onFinish,
    }: toggleVisibilityAnimatedOptions,
  ): void {
    const element = resolveElement(elementOrId);
    if (!element) {
      return;
    }

    let animationId: string;
    if (id) {
      animationId = id;
    } else if (typeof elementOrId === 'string') {
      animationId = elementOrId;
    } else {
      return;
    }

    if (show === visible[animationId]) {
      return;
    }
    visible[animationId] = show;

    // Check if animation is ongoing
    let timeout = timeoutFunctions[animationId];
    if (timeout) {
      clearTimeout(timeout);
    }
    delete timeoutFunctions[animationId];
    timeout = null;

    // Accessibility
    element?.setAttribute('aria-hidden', show ? 'false' : 'true');

    if (show) {
      toggleVisibility(element, true);
      element.getBoundingClientRect();
    }

    // Clean
    toggleClass(element, `${classPrefix}--exit`, !show);
    toggleClass(element, `${classPrefix}--exiting`, false);
    toggleClass(element, `${classPrefix}--exited`, false);

    toggleClass(element, `${classPrefix}--enter`, show);
    toggleClass(element, `${classPrefix}--entering`, false);
    toggleClass(element, `${classPrefix}--entered`, false);

    // Enter/Exit
    // toggleClass(
    //   element,
    //   show ? `${classPrefix}--enter` : `${classPrefix}--exit`,
    //   true,
    // );

    const initialHeight = element.scrollHeight;
    if (animateHeight) {
      if (!autoHeight) {
        element.style.height = !show ? `${element.scrollHeight}px` : '';
      } else {
        element.style.height = show ? `0px` : `${element.scrollHeight}px`;
      }
    }

    element?.getBoundingClientRect();

    // Entering/Exiting
    toggleClass(
      element,
      show ? `${classPrefix}--entering` : `${classPrefix}--exiting`,
      true,
    );
    if (animateHeight) {
      if (!autoHeight) {
        element.style.height = '';
      } else {
        element.style.height = show ? `${initialHeight}px` : `0px`;
      }
    }
    element?.getBoundingClientRect();

    // Waiting
    const fn = () => {
      toggleClass(
        element,
        show ? `${classPrefix}--entering` : `${classPrefix}--exiting`,
        false,
      );
      toggleClass(
        element,
        show ? `${classPrefix}--entered` : `${classPrefix}--exited`,
        true,
      );

      delete timeoutFunctions[animationId];
      if (!show) {
        toggleVisibility(element, false);
      }
      if (animateHeight) {
        if (show) {
          element.style.height = autoHeight ? 'auto' : '';
        } else {
          element.style.height = '';
        }
      }
      onFinish?.();
    };

    if (duration) {
      timeout = setTimeout(fn, duration);
      timeoutFunctions[animationId] = timeout;
    } else {
      fn();
    }
  };
}

import { ChevronLeft, User, MapPin, Hash, AlertOctagon } from 'preact-feather';

import visaIcon from './card-visa.png';
import mastercardIcon from './card-mastercard.png';
import amexIcon from './card-american-express.png';
import jcbIcon from './card-jcb.png';
import discoverIcon from './card-discover.png';
import genericIcon from './card-generic.png';
import paypalIcon from './paypal.png';
import editIcon from './edit.png';
import directDebitIcon from './direct-debit.svg';

export const Icons = {
  visa: visaIcon,
  mastercard: mastercardIcon,
  americanExpress: amexIcon,
  jcb: jcbIcon,
  discover: discoverIcon,
  genericCard: genericIcon,
  paypal: paypalIcon,
  edit: editIcon,
  directDebit: directDebitIcon,

  goBack: <ChevronLeft class='icon' />,
  user: <User class='icon' />,
  location: <MapPin class='icon' />,
  hash: <Hash class='icon' />,
  error: <AlertOctagon class='icon' />,
};

export const translation = {
  navigateToPaymentMethods: 'Andere Zahlungsmethode wählen',
  payWithCard: 'Mit Kreditkarte zahlen',
  cardNumber: 'Kartennummer',
  cardExpiry: 'ExpiryGültig bis',
  cardExpiryPlaceholder: 'MM/JJ',
  cardCVV: 'Prüfnr',
  cardholderName: 'Name des Karteninhabers',
  cardholderNamePlaceholder: 'vollständiger Name',
  previouslyUsed: 'Zuvor verwendet:',
  forgetPaymentMethods: 'Zahlungsmethoden löschen',
  forgetSavedCard: 'Karte mit den letzten Ziffern {{last4Digits}} löschen',
  required: 'Erforderlich',
  invalid: 'Ungültig',
  forgetPayPal: 'PayPal-Konto löschen',
  savePaymentMethod: 'Zahlungsmethode speichern',
  managePaymentMethods: 'Verwalten Sie Ihre Zahlungsmethoden',
  savedPaymentMethodsEmpty:
    'Geben Sie unten Ihre Kartendaten ein, um eine neue Zahlungsmethode hinzuzufügen',
  newPaymentMethod: 'Fügen Sie eine neue Zahlungsmethode hinzu',
  cardEndingWith: 'Karte endet mit',
  otherWaysToPay: 'Andere Zahlungsmöglichkeiten',
  pay: 'Zahlen',
  addCard: 'Karte hinzufügen',
  // Payment methods dropdown
  editPaymentMethods: 'Bearbeiten Sie gespeicherte Zahlungsmethoden',
  confirmCardDeletion: 'Bestätigen - Löschen Karte',
  confirmPayPalDeletion: 'Bestätigen - Löschen PayPal',
  delete: 'Löschen',
  cancel: 'Stornieren',
  // Direct Debit Form
  accountHolder: 'Kontoinhaber',
  address: 'Adresse',
  addressLine1: 'Adresse Linie 1',
  addressLine2: 'Adresse Linie 2',
  fullName: 'Vollständiger Name',
  email: 'E-mail',
  city: 'Stadt',
  postalCode: 'Postleitzahl',
  setUpDirectDebit: 'Einrichten Direct Debit',
};

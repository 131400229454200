import { TranslationUnit } from '../assets/translations/Translations';
import { ElementID } from '../enums/Checkout';
import { locateElement } from '../utils/dom';
import { getAttribute } from './DomUtilities';
import { IFieldUtils } from './ui/FieldUtils';

interface MandateOptions {
  companyName: string;
  companyAddress: string;
  smallPrint: string;
}

export function setValidationErrors(
  errors: Record<string, 'required' | 'invalid' | null> = {},
  translation: TranslationUnit,
  fieldUtils: IFieldUtils,
): void {
  const translate = (value: 'required' | 'invalid' | null): string | null => {
    if (value) {
      return translation[value];
    }
    return null;
  };

  // IBAN
  if (errors.iban) {
    fieldUtils.setErrorState('primer-checkout-dd-iban', translate(errors.iban));
  } else {
    fieldUtils.setErrorState('primer-checkout-dd-iban', null);
  }

  // ACCOUNT HOLDER
  if (errors.customerName || errors.customerEmail) {
    fieldUtils.setErrorState(
      'primer-checkout-dd-customer-name',
      translate(errors.customerName ?? errors.customerEmail),
    );
  } else {
    fieldUtils.setErrorState('primer-checkout-dd-customer-name', null);
  }

  // ADDRESS
  if (
    errors.customerAddressLine1 ||
    errors.customerCity ||
    errors.customerPostalCode
  ) {
    fieldUtils.setErrorState(
      'primer-checkout-dd-customer-address',
      translate(
        errors.customerAddressLine1 ??
          errors.customerCity ??
          errors.customerPostalCode,
      ),
    );
  } else {
    fieldUtils.setErrorState('primer-checkout-dd-customer-address', null);
  }
}

export function setMandateDetails(options: MandateOptions): void {
  setTextContent(
    'primer-checkout-dd-mandate-customer-name',
    getAttribute(ElementID.DD_CUSTOMER_NAME_INPUT, 'value') || '',
  );

  setTextContent(
    'primer-checkout-dd-mandate-customer-address',
    getFormattedAddress(),
  );

  setTextContent(
    'primer-checkout-dd-mandate-iban',
    getAttribute(ElementID.DD_IBAN_INPUT, 'value') || '',
  );

  setTextContent(
    'primer-checkout-dd-creditor-details',
    [options.companyName, options.companyAddress].join(', '),
  );

  // setTextContent('primer-checkout-small-print', options.smallPrint);
}

function setTextContent(id: string, text: string) {
  const element = locateElement(`#${id}`) as HTMLElement;
  element.textContent = text;
}

function getFormattedAddress(): string {
  const line1 =
    getAttribute(ElementID.DD_CUSTOMER_ADDRESS_LINE1, 'value') || '';
  const city = getAttribute(ElementID.DD_CUSTOMER_ADDRESS_CITY, 'value') || '';
  const postalCode =
    getAttribute(ElementID.DD_CUSTOMER_ADDRESS_POSTAL_CODE, 'value') || '';

  return [line1, city, postalCode].join(', ');
}

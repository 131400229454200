import { Api } from '../core/Api';
import { PaymentMethodToken } from '../types';

interface VaultServices {
  api: Api;
}

export class Vault {
  private api: Api;

  constructor(services: VaultServices) {
    this.api = services.api;
  }

  public async fetch(
    customerId?: Nullable<string>,
  ): Promise<PaymentMethodToken[]> {
    let url = '/payment-instruments';

    if (customerId) {
      url += `?customer_id=${customerId}`;
    }

    const response = await this.api.get<{ data: PaymentMethodToken[] }>(url);

    if (!response.data) {
      return [];
    }

    return response.data.data || [];
  }

  async delete(id: Nullable<string>): Promise<boolean> {
    if (!id) {
      return false;
    }

    const response = await this.api.delete(`/payment-instruments/${id}/vault`);

    return !response.error;
  }
}

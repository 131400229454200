import deepmerge from 'deepmerge';
import { createToggleVisibilityAnimated } from './ElementVisibility';
import { FieldUtils, IFieldUtils } from './FieldUtils';
import { createStyleManager, IStyleManager } from './StyleManager';
import { defaultCheckoutStyle } from './style/defaultStyle';
import { IViewUtils, toggleVisibilityAnimatedFn } from './types';
import { CheckoutOptions, VaultManagerOptions } from '../../types';
import { CheckoutStyle } from '../../styles';
import CheckoutStore from '../../store/CheckoutStore';

export class ViewUtils implements IViewUtils {
  public toggleVisibilityAnimated: toggleVisibilityAnimatedFn;

  public store: CheckoutStore;

  public fieldUtils: IFieldUtils;

  public styleManager: IStyleManager;
}

function createViewUtils(
  store: CheckoutStore,
  style?: CheckoutStyle,
): ViewUtils {
  const viewUtils = new ViewUtils();

  viewUtils.toggleVisibilityAnimated = createToggleVisibilityAnimated();
  viewUtils.store = store;
  viewUtils.fieldUtils = new FieldUtils(viewUtils);
  viewUtils.styleManager = createStyleManager();
  viewUtils.styleManager.setStyle(deepmerge(defaultCheckoutStyle, style ?? {}));

  return viewUtils;
}

export const createCheckoutViewUtils = (
  store: CheckoutStore,
  { style }: CheckoutOptions,
): ViewUtils => createViewUtils(store, style);

export const createVaultManagerViewUtils = (
  store: CheckoutStore,
  { style }: VaultManagerOptions,
): ViewUtils => createViewUtils(store, style);

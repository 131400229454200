import { Validation } from '../types';

export class BasePaymentMethod {
  public displayName: string;

  public container: Nullable<Element>;

  constructor(name: string) {
    this.displayName = name;
  }

  setupAndValidate(): Promise<boolean> {
    return Promise.resolve(true);
  }

  mount(): Promise<void> {
    return Promise.resolve();
  }

  tokenize(): Promise<void> {
    return Promise.resolve();
  }

  validate(): Promise<Validation> {
    return Promise.resolve({ valid: true });
  }
}

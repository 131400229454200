import { ClassName } from '../enums/Checkout';
import { toggleClass } from '../utils/toggleClass';
import window from '../utils/window';
import { massToggle } from './DomUtilities';

interface CardMetadata {
  type?: string;
  possibleTypes: string[];
}

export function cardTypeChangeHandler(meta: CardMetadata): void {
  const types = meta.possibleTypes.slice(0, 3);

  massToggle(ClassName.CARD_TYPE, ClassName.HIDDEN, () => true);

  types.forEach((type: string) => {
    const elm =
      window.document.getElementById(`primer-checkout-card-type-${type}`) ||
      window.document.getElementById('primer-checkout-card-type-generic-card');

    if (!elm) {
      return;
    }

    toggleClass(elm, ClassName.HIDDEN, false);
  });
}

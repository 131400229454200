import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import { Scene } from '../../enums/Checkout';
import { useCheckoutContext } from '../../store/CheckoutContext';
import {
  useCheckoutStore,
  useSelector,
} from '../../store/CheckoutStoreContext';
import {
  useFirstScene,
  useScene,
  useIsCurrentScene,
  useSceneMounting,
  useSceneEntering,
} from '../../utils/hooks';
import * as DirectDebitUtils from '../../checkout/DirectDebitUtils';
import { Icons } from '../../assets/icons/Icons';
import { getFirstFocusableChild } from '../../checkout/focus';
import { useSceneLoaderContext } from '../../utils/SceneLoader/SceneLoaderContext';
import { noop } from '../../utils/noop';

const SCENE = Scene.DIRECT_DEBIT_FORM;

const DirectDebitFormScene = () => {
  const store = useCheckoutStore();
  const { viewUtils, options } = useCheckoutContext();
  const { directDebit } = useSelector((s) => s.paymentMethods);
  const labels = useSelector((s) => s.translation);
  const { setIsMounted } = useSceneLoaderContext();

  const isCurrentScene = useIsCurrentScene(SCENE);

  const sceneRef = useRef<HTMLDivElement>();

  const [fullName, setFullName] = useState<string | undefined>(undefined);
  const [emailAddress, setEmailAddress] = useState<string | undefined>(
    undefined,
  );
  const [addressLine1, setAddressLine1] = useState<string | undefined>(
    undefined,
  );
  const [addressLine2, setAddressLine2] = useState<string | undefined>(
    undefined,
  );
  const [postalCode, setPostalCode] = useState<string | undefined>(undefined);
  const [city, setCity] = useState<string | undefined>(undefined);
  const [iban, setIban] = useState<string | undefined>(undefined);

  useFirstScene(() => {
    // Prefill
    setFullName(options.directDebit?.customerName);
    setEmailAddress(options.directDebit?.customerEmail);
    setAddressLine1(options.directDebit?.customerAddressLine1);
    setAddressLine2(options.directDebit?.customerAddressLine2);
    setPostalCode(options.directDebit?.customerPostalCode);
    setCity(options.directDebit?.customerCity);
    setIban(options.directDebit?.iban);
  }, SCENE);

  useSceneEntering(() => {
    store.setSmallPrintMessage(null);
    store.setSubmitButtonContent(
      labels?.setUpDirectDebit ?? '[SETUPDIRECTDEBIT]',
    );
  }, SCENE);

  useScene(() => {
    setTimeout(() => {
      const el = getFirstFocusableChild(sceneRef.current);
      el?.focus();
    }, 300);
  }, SCENE);

  useSceneMounting(() => {
    setIsMounted();
  }, SCENE);

  useLayoutEffect(() => {
    if (!isCurrentScene) return noop;

    const handleSubmitButtonClick = async () => {
      store.setIsLoading(true);
      const validation = await directDebit.validate();

      DirectDebitUtils.setValidationErrors(
        validation.validationErrors,
        store.getTranslations(),
        viewUtils.fieldUtils,
      );

      if (!validation.valid) {
        store.setIsLoading(false);
        return;
      }

      store.produceState((draft) => {
        draft.directDebit.customerName = fullName as string;
        draft.directDebit.customerIban = iban as string;
        draft.directDebit.customerAddress = [
          addressLine1,
          city,
          postalCode,
        ].join(', ');
      });

      store.setScene(Scene.DIRECT_DEBIT_MANDATE);
    };

    store.addSubmitButtonClickListener(handleSubmitButtonClick);
    return () => {
      store.removeSubmitButtonClickListener(handleSubmitButtonClick);
    };
  }, [isCurrentScene, fullName, iban, addressLine1, city, postalCode]);

  const handleBackClick = () => {
    store.setScene(Scene.CHOOSE_PAYMENT_METHOD);
  };

  return (
    <div
      ref={sceneRef}
      id='primer-checkout-scene-direct-debit-form'
      class='PrimerCheckout__scene PrimerCheckout__scene--directDebitForm PrimerCheckout--exited'
    >
      <div class='PrimerCheckout__formHeader'>
        <button
          type='button'
          id='primer-checkout-dd-go-back'
          class='PrimerCheckout__backButton'
          onClick={handleBackClick}
        >
          {Icons.goBack}
        </button>
        <img src={Icons.directDebit} height='20' />
      </div>
      <div class='PrimerCheckout__sceneElement PrimerCheckout__form'>
        <div
          id='primer-checkout-dd-customer-name-field'
          class='PrimerCheckout__formField'
        >
          <label
            class='PrimerCheckout__label'
            for='primer-checkout-dd-customer-name-input'
          >
            {labels?.accountHolder}
          </label>

          <div class='PrimerCheckout__formInputs PrimerCheckout__formInputs--vertical'>
            <input
              id='primer-checkout-dd-customer-name-input'
              class='PrimerCheckout__formInput'
              type='text'
              placeholder={labels?.fullName}
              autocomplete='name'
              value={fullName}
              onInput={(e) => setFullName(e.target?.value)}
            />
            <input
              id='primer-checkout-dd-customer-email-input'
              class='PrimerCheckout__formInput'
              type='text'
              placeholder='me@domain.com'
              autocomplete='email'
              value={emailAddress}
              onInput={(e) => setEmailAddress(e.target?.value)}
            />
          </div>

          <div
            id='primer-checkout-dd-customer-name-error'
            class='PrimerCheckout__helperText PrimerCheckout__helperText--error'
          ></div>
        </div>

        <div
          id='primer-checkout-dd-customer-address-field'
          class='PrimerCheckout__formField'
        >
          <label
            class='PrimerCheckout__label'
            for='primer-checkout-dd-customer-address-input'
          >
            {labels?.address}
          </label>

          <div class='PrimerCheckout__formInputs PrimerCheckout__formInputs--vertical'>
            <input
              id='primer-checkout-dd-customer-address-input'
              class='PrimerCheckout__formInput'
              type='text'
              autocomplete='address-line1'
              placeholder={labels?.addressLine1}
              value={addressLine1}
              onInput={(e) => setAddressLine1(e.target?.value)}
            />
            <input
              id='primer-checkout-dd-customer-address-line2-input'
              class='PrimerCheckout__formInput'
              type='text'
              autocomplete='address-line2'
              placeholder={labels?.addressLine2}
              value={addressLine2}
              onInput={(e) => setAddressLine2(e.target?.value)}
            />
            <div class='PrimerCheckout__formInputs PrimerCheckout__formInputs--horizontal'>
              <input
                id='primer-checkout-dd-customer-postal-code-input'
                class='PrimerCheckout__formInput'
                type='text'
                placeholder={labels?.postalCode}
                autocomplete='postal-code'
                value={postalCode}
                onInput={(e) => setPostalCode(e.target?.value)}
              />
              <input
                id='primer-checkout-dd-customer-city-input'
                class='PrimerCheckout__formInput'
                type='text'
                placeholder={labels?.city}
                autocomplete='address-level2'
                value={city}
                onInput={(e) => setCity(e.target?.value)}
              />
            </div>
          </div>

          <div
            id='primer-checkout-dd-customer-address-error'
            class='PrimerCheckout__helperText PrimerCheckout__helperText--error'
          ></div>
        </div>

        <div
          id='primer-checkout-dd-iban-field'
          class='PrimerCheckout__formField'
        >
          <label
            class='PrimerCheckout__label'
            for='primer-checkout-dd-iban-input'
          >
            IBAN
          </label>

          <input
            id='primer-checkout-dd-iban-input'
            class='PrimerCheckout__formInput'
            type='text'
            placeholder='e.g. GB60 BARC 2000 0055 7799 11'
            value={iban}
            onInput={(e) => setIban(e.target?.value)}
          />

          <div
            id='primer-checkout-dd-iban-error'
            class='PrimerCheckout__helperText PrimerCheckout__helperText--error'
          ></div>
        </div>
      </div>
    </div>
  );
};

export default DirectDebitFormScene;

export enum PaymentInstrumentType {
  CARD = 'PAYMENT_CARD',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  PAYPAL = 'PAYPAL_ORDER',
  PAYPAL_VAULTED = 'PAYPAL_BILLING_AGREEMENT',
}

export enum PaymentMethodType {
  PAYMENT_CARD = 'PAYMENT_CARD',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  PAYPAL = 'PAYPAL',
  GO_CARDLESS = 'GOCARDLESS',
}

export enum TokenType {
  SINGLE_USE = 'SINGLE_USE',
  MULTI_USE = 'MULTI_USE',
}

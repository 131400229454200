export enum ApiEvent {
  startedCheckout = 'STARTED_CHECKOUT',
  loadedCheckoutUi = 'LOADED_CHECKOUT_UI',
  loadedVaultManagerUi = 'LOADED_VAULT_MANAGER_UI',
  completedCheckout = 'COMPLETED_CHECKOUT',
  triggered3ds = 'TRIGGERED_3DS',
  validationError = 'VALIDATION_ERROR',
  tokenizationError = 'TOKENIZATION_ERROR',
  threeDSecureError = '3DS_ERROR',
  threeDSecureFailed = '3DS_FAIL',
  threeDSecureSuccess = '3DS_SUCCESS',
  threeDSecureSkipped = '3DS_SKIPPED',
}

export enum ClickEvent {
  clickedSubmitButton = 'CLICKED_SUBMIT_BUTTON',
}

export enum MockEvent {
  mock = 'MOCK',
}

export const translation = {
  navigateToPaymentMethods: 'Scegli di pagare in un altro modo',
  payWithCard: 'Paga con una carta',
  cardNumber: 'Numero di carta',
  cardExpiry: 'Data di scadenza',
  cardExpiryPlaceholder: 'MM/AA',
  cardCVV: 'CVV',
  cardholderName: 'Titolare della carta',
  cardholderNamePlaceholder: 'Nome e cognome',
  previouslyUsed: 'Precedentemente utilizzato:',
  forgetPaymentMethods: 'Elimina i metodi di pagamento',
  forgetSavedCard: 'Elimina la carta che termina con {{last4Digits}}',
  required: 'Obbligatorio',
  invalid: 'Non valido',
  forgetPayPal: "Elimina l'account PayPal",
  savePaymentMethod: 'Salva metodo di pagamento',
  managePaymentMethods: 'Gestisci i tuoi metodi di pagamento',
  savedPaymentMethodsEmpty:
    'Inserisci i dati della tua carta di seguito per aggiungere un nuovo metodo di pagamento',
  newPaymentMethod: 'Aggiungi un nuovo metodo di pagamento',
  cardEndingWith: 'Carta che termina con',
  otherWaysToPay: 'Altri modi per pagare',
  pay: 'Paga',
  addCard: 'Aggiungi carta',
  // Payment methods dropdown
  editPaymentMethods: 'Modifica i metodi di pagamento salvati',
  confirmCardDeletion: 'Confermare - Elimina carta',
  confirmPayPalDeletion: 'Confermare - Elimina PayPal',
  delete: 'Elimina',
  cancel: 'Annulla',
  // Direct Debit Form
  accountHolder: 'Titolare del Conto',
  address: 'Indirizzo',
  addressLine1: 'Indirizzo linea 1',
  addressLine2: 'Indirizzo linea 2',
  fullName: 'Come e Cognome',
  email: 'E-mail',
  city: 'Città',
  postalCode: 'Codice Postale',
  setUpDirectDebit: 'Impostare Direct Debit',
};

import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import { Icons } from '../assets/icons/Icons';
import { ElementID } from '../enums/Checkout';
import { useCheckoutContext } from '../store/CheckoutContext';
import { useSelector } from '../store/CheckoutStoreContext';
import { useIsFirstRender } from '../utils/hooks';

const DURATION = 300;

const ErrorMessage = () => {
  const { viewUtils } = useCheckoutContext();
  const message = useSelector((s) => s.error.message);
  const [content, setContent] = useState(message);
  const isVisible = useRef(false);
  const isFirstRender = useIsFirstRender();

  const toggleVisibility = (show: boolean, animated = true): void => {
    isVisible.current = false;

    viewUtils.toggleVisibilityAnimated(ElementID.ERROR_MESSAGE, show, {
      duration: animated ? DURATION : 0,
      animateHeight: true,
      autoHeight: true,
    });
  };

  useLayoutEffect(() => {
    if (!message) {
      toggleVisibility(false, !isFirstRender);
    } else {
      toggleVisibility(true, !isFirstRender);
      setContent(message);
    }
  }, [message]);

  return (
    <div id='primer-checkout-error-message'>
      <div class='PrimerCheckout__errorMessage'>
        {Icons.error}
        <span>{content}</span>
      </div>
    </div>
  );
};

export default ErrorMessage;

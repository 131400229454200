import { useLayoutEffect, useRef } from 'preact/hooks';
import { useCheckoutContext } from '../store/CheckoutContext';
import { useCheckoutStore, useSelector } from '../store/CheckoutStoreContext';
import {
  useIsCurrentScene,
  useSceneEntering,
  useSceneMounting,
} from '../utils/hooks';
import { Scene } from '../enums/Checkout';
import CreditCardForm from '../payment-methods/credit-card/CreditCardForm';
import VaultMenu from '../components/VaultMenu';
import VaultedPaymentMethod from '../components/VaultedPaymentMethod';
import { AnimatePresence } from '../utils/AnimatePresence';
import { useSceneLoaderContext } from '../utils/SceneLoader/SceneLoaderContext';
import {
  useWait,
  WaitForComponents,
} from '../utils/SceneLoader/WaitForComponents';

const SCENE = Scene.MANAGE_PAYMENT_METHODS;

const VaultManagerScene = () => {
  const store = useCheckoutStore();
  const { viewUtils, checkout } = useCheckoutContext();
  const { setIsMounted } = useSceneLoaderContext();
  const vault = useSelector((s) => s.vault);
  const labels = useSelector((s) => s.translation);

  const emptyTextRef = useRef<HTMLDivElement>();
  const isCurrentScene = useIsCurrentScene(Scene.MANAGE_PAYMENT_METHODS);

  const { ready } = useWait(() => {
    setIsMounted();
  }, ['components', 'animation']);

  const toggleEmpty = async (
    isEmpty: boolean,
    animated: boolean,
  ): Promise<void> => {
    viewUtils.toggleVisibilityAnimated('primer-checkout-actions', !isEmpty, {
      duration: animated ? 300 : 0,
    });

    viewUtils.toggleVisibilityAnimated(
      'primer-checkout-saved-payment-methods-empty-text',
      isEmpty,
      {
        duration: animated ? 300 : 0,
        animateHeight: true,
        autoHeight: true,
      },
    );

    viewUtils.toggleVisibilityAnimated(
      'primer-checkout-payment-method-divider',
      !isEmpty,
      {
        duration: animated ? 300 : 0,
        animateHeight: true,
        autoHeight: true,
      },
    );
  };

  useSceneMounting(() => {
    (async () => {
      await toggleEmpty(!store.hasVault, false);
      ready('animation');
    })();
  }, SCENE);

  useLayoutEffect(() => {
    if (isCurrentScene) {
      toggleEmpty(!store.hasVault, true);
    }
  }, [store.hasVault]);

  useSceneEntering(() => {
    const handleSubmitButtonClick = async () => {
      await checkout.tokenize();
    };

    store.setSubmitButtonVisible(true);
    store.setSubmitButtonContent(labels?.addCard ?? 'add card');

    store.addSubmitButtonClickListener(handleSubmitButtonClick);
    return () => {
      store.removeSubmitButtonClickListener(handleSubmitButtonClick);
    };
  }, Scene.MANAGE_PAYMENT_METHODS);

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////

  return (
    <WaitForComponents onReady={() => ready('components')}>
      <div
        id='primer-checkout-scene-manage-payment-methods'
        class='PrimerCheckout__scene PrimerCheckout__scene--managePaymentMethods PrimerCheckout--exited'
      >
        <div>
          <div
            id='primer-checkout-vault'
            class='PrimerCheckout__sceneElement PrimerCheckout__savedPaymentMethods'
          >
            <div class='PrimerCheckout__savedPaymentMethods__title'>
              <div class='PrimerCheckout__label PrimerCheckout__label--muted'>
                {labels?.managePaymentMethods}
              </div>
              <div class='PrimerCheckout__savedPaymentMethodActions'>
                <VaultMenu />
              </div>
            </div>

            <div
              ref={emptyTextRef}
              id='primer-checkout-saved-payment-methods-empty-text'
              class='PrimerCheckout__emptyText'
            >
              <span class='PrimerCheckout__label'>
                {labels?.savedPaymentMethodsEmpty}
              </span>
            </div>

            <div class='PrimerCheckout__savedPaymentMethodContainer'>
              <AnimatePresence>
                {vault.items.map((vaultedItem) => (
                  <VaultedPaymentMethod
                    key={vaultedItem.id}
                    vaultedItem={vaultedItem}
                  />
                ))}
              </AnimatePresence>
            </div>
          </div>

          <div
            id='primer-checkout-payment-method-divider'
            class='PrimerCheckout__sceneElement PrimerCheckout__textDivider PrimerCheckout__divider'
          >
            <span class='PrimerCheckout__label PrimerCheckout__label--muted'>
              {labels?.newPaymentMethod}
            </span>
          </div>
          <CreditCardForm />
          <div></div>
        </div>
      </div>
    </WaitForComponents>
  );
};

export default VaultManagerScene;

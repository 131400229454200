import jss, { JssStyle, StyleSheet } from 'jss';
import preset from 'jss-preset-default';
import { ElementID, Scene } from '../../../enums/Checkout';

import {
  AlternativePaymentMethodButtonStyle,
  BackButtonStyle,
  BaseInputStyle,
  BlockStyle,
  CheckoutStyle,
  DirectDebitMandateStyle,
  ErrorMessageStyle,
  InputStyle,
  InputStyles,
  LoadingScreenStyle,
  SavedPaymentMethodButtonStyle,
  SavedPaymentMethodButtonStyles,
  SeparatorStyle,
  ShowMorePaymentMethodsButtonStyles,
  SubmitButtonStyle,
  SubmitButtonStyles,
  TextStyle,
} from '../../../styles';
import { IStyleManager } from '../StyleManager';

///////////////////////////////////////////
// Utils
///////////////////////////////////////////
const className = (name: string) => `.PrimerCheckout__${name}`;
const state = (name: string) => `.PrimerCheckout--${name}`;

///////////////////////////////////////////
// Conversion to JSS
///////////////////////////////////////////
const getTextStyle = (style?: TextStyle): JssStyle => {
  return {
    color: style?.color,
    fontFamily: style?.fontFamily,
    fontWeight: style?.fontWeight,
    fontSize: style?.fontSize,
    fontSmoothing: style?.fontSmoothing,
    lineHeight: style?.lineHeight,
  } as JssStyle;
};

const getBlockStyle = (style?: BlockStyle): JssStyle => {
  return {
    background: style?.background,
    borderStyle: style?.borderStyle,
    borderWidth: style?.borderWidth,
    borderColor: style?.borderColor,
    borderRadius: style?.borderRadius,
    boxShadow: style?.boxShadow,
  } as JssStyle;
};

const getBaseInputStyle = (style?: BaseInputStyle): JssStyle => {
  return {
    ...getTextStyle(style),
    ...getBlockStyle(style),
    height: style?.height,
    paddingLeft: style?.paddingHorizontal,
    paddingRight: style?.paddingHorizontal,
  } as JssStyle;
};

const getHostedFieldInputStyles = (style?: InputStyle): JssStyle => {
  return {
    ...getTextStyle(style),
    '&:hover': {
      ...getTextStyle(style?.hover),
    },
    '&:focus': {
      ...getTextStyle(style?.focus),
    },
    '&::placeholder': {
      ...getTextStyle(style?.placeholder),
    },
    '&::selection': {
      ...getTextStyle(style?.selection),
    },
  };
};

export const getJSSHostedFieldStyle = (style?: CheckoutStyle): JssStyle => {
  return {
    '@font-face': style?.fontFaces as any,

    '@global': {
      'input': {
        ...getHostedFieldInputStyles(style?.input?.base),
        'input:-webkit-autofill, input:-webkit-autofill:input:focus, input:-webkit-autofill:hover': {
          ...getTextStyle(style?.input?.base?.webkitAutofill),
        },
      },

      // Error + Submitted
      '#primer-hosted-form.submitted': {
        '& input': {
          '&.error': {
            ...getHostedFieldInputStyles(style?.input?.error),
          },
        },
      },
    },
  };
};

const getInputStyle = (style?: InputStyle): JssStyle => {
  return {
    ...getBaseInputStyle(style),
    '&:hover': {
      ...getBaseInputStyle(style?.hover),
    },
    [`&:focus, &${state('focused')}`]: {
      ...getBaseInputStyle(style?.focus),
    },
    '&::placeholder': {
      ...getBaseInputStyle(style?.placeholder),
    },
    '&::selection': {
      ...getBaseInputStyle(style?.selection),
    },
    '&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover': {
      ...getBaseInputStyle(style?.webkitAutofill),
    },
  };
};

const getInputStyles = (style?: InputStyles): JssStyle => {
  const borderWidthMargin = style?.base?.borderWidth
    ? -style.base.borderWidth
    : undefined;

  return {
    // Input states
    [className('formInput')]: getInputStyle(style?.base),
    // Error
    [`${state('error')}`]: {
      [`& ${className('formInput')}`]: getInputStyle(style?.error),
    },

    // Multiple inputs round
    [`${className('formInputs--vertical')}`]: {
      '& > *:first-child': {
        borderTopLeftRadius: style?.base?.borderRadius,
        borderTopRightRadius: style?.base?.borderRadius,
      },
      '& > *:last-child': {
        borderBottomLeftRadius: style?.base?.borderRadius,
        borderBottomRightRadius: style?.base?.borderRadius,
      },
      '& > .PrimerCheckout__formInputs--horizontal:last-child': {
        '& > *:first-child': {
          borderBottomLeftRadius: style?.base?.borderRadius,
        },
        '& > *:last-child': {
          borderBottomRightRadius: style?.base?.borderRadius,
        },
      },

      // Multiple inputs border width
      [`& ${className('formInput')} + ${className('formInput')} `]: {
        marginTop: borderWidthMargin,
      },
    },
    // Multiple inputs border width
    [`${className('formInputs--horizontal')}`]: {
      [`& ${className('formInput')}`]: {
        marginTop: borderWidthMargin,
      },
      [`& ${className('formInput')} + ${className('formInput')} `]: {
        marginLeft: borderWidthMargin,
      },
    },
  } as JssStyle;
};

const getSubmitButtonStyle = (style?: SubmitButtonStyle) => {
  return {
    ...getTextStyle(style),
    ...getBlockStyle(style),
  };
};

const getSubmitButtonStyles = (style?: SubmitButtonStyles): JssStyle => {
  return {
    // Base
    [className('submitButton')]: {
      ...getSubmitButtonStyle(style?.base),
    },

    // Loading state
    [`${state('loading')} ${className('submitButton')}`]: {
      ...getSubmitButtonStyle(style?.loading),
    },

    // Loading indicator
    [`${className('loadingRing')} .spinner .path`]: {
      stroke: style?.loading?.color ?? style?.base?.color,
    },
  } as JssStyle;
};

const getLoadingScreenStyle = (style?: LoadingScreenStyle): JssStyle => {
  return {
    [`${className('spinner')} .spinner .path`]: {
      stroke: style?.color,
    },
  } as JssStyle;
};

const getShowMorePaymentMethodsButtonStyles = (
  style?: ShowMorePaymentMethodsButtonStyles,
): JssStyle => {
  return {
    [`#${ElementID.NAVIGATE_PAYMENT_METHODS}`]: {
      [`& ${className('label--primary')}`]: getTextStyle(style?.base),
      [`&[disabled='true'] ${className('label')}`]: getTextStyle(
        style?.disabled,
      ),
    },
  } as JssStyle;
};

const getInputLabelStyle = (style?: TextStyle) => {
  return {
    [`label${className('label')}`]: getTextStyle(style),
  } as JssStyle;
};

const getSavedPaymentButtonStyle = (style?: SavedPaymentMethodButtonStyle) => {
  return {
    ...getTextStyle(style),
    ...getBlockStyle(style),
    '&:hover': {
      ...getTextStyle(style?.hover),
      ...getBlockStyle(style?.hover),
    },
    '&:focus': {
      ...getTextStyle(style?.focus),
      ...getBlockStyle(style?.focus),
    },
  } as JssStyle;
};

const getSavedPaymentButtonStyles = (
  style?: SavedPaymentMethodButtonStyles,
) => {
  return {
    [className('savedPaymentMethod')]: {
      ...getSavedPaymentButtonStyle(style?.base),
      [`&${state('selected')}`]: {
        ...getSavedPaymentButtonStyle(style?.selected),
      },
    },
  } as JssStyle;
};

const getInputErrorTextStyle = (style?: TextStyle): JssStyle => {
  return {
    [className('helperText--error')]: getTextStyle(style),
  };
};

const getDirectDebitMandateStyle = (
  style?: DirectDebitMandateStyle,
): JssStyle => {
  return {
    [`#${Scene.DIRECT_DEBIT_MANDATE}`]: {
      [`& ${className('title')}`]: getTextStyle(style?.header),
      [`& ${className('mandateItem')}`]: {
        '& .icon': {
          color: style?.label?.color,
        },
      },
      [`& ${className('mandateDetail')}`]: {
        '& > *:first-child': getTextStyle(style?.label),
        '& > *:last-child': getTextStyle(style?.content),
      },
    },
    [`#primer-checkout-dd-creditor-details`]: getTextStyle(
      style?.creditorDetails,
    ),
  } as JssStyle;
};

const getSmallPrintStyle = (style?: TextStyle): JssStyle => {
  return {
    [`#primer-checkout-small-print`]: getTextStyle(style),
  };
};

const getAlternativePaymentMethodButtonStyle = (
  style?: AlternativePaymentMethodButtonStyle,
): JssStyle => {
  return {
    [className('apmButton')]: {
      height: style?.height,
      borderRadius: style?.borderRadius,
    },
    [`${className('apmButton--managed')}`]: {
      '& button': getBlockStyle(style),
    },
  } as JssStyle;
};

const getBackButtonStyle = (style?: BackButtonStyle): JssStyle => {
  return {
    [className('backButton')]: {
      color: style?.color,
    },
  } as JssStyle;
};

const getSeparatorStyle = (style?: SeparatorStyle): JssStyle => {
  return {
    [className('textDivider')]: {
      '&::before, &::after': {
        background: style?.color,
      },
    },
    [`#primer-checkout-dd-creditor-details`]: {
      borderTopColor: style?.color,
      borderBottomColor: style?.color,
    },
  } as JssStyle;
};

const getErrorMessageStyle = (style?: ErrorMessageStyle): JssStyle => {
  return {
    [className('errorMessage')]: {
      ...getTextStyle(style),
      ...getBlockStyle(style),
    },
  };
};

export const getJSSGlobalStyle = (style?: CheckoutStyle): JssStyle => {
  return {
    '@global': {
      ...getLoadingScreenStyle(style?.loadingScreen),
      ...getInputStyles(style?.input),
      ...getInputLabelStyle(style?.inputLabel),
      ...getInputErrorTextStyle(style?.inputErrorText),
      ...getShowMorePaymentMethodsButtonStyles(
        style?.showMorePaymentMethodsButton,
      ),
      ...getAlternativePaymentMethodButtonStyle(
        style?.alternativePaymentMethodButton,
      ),
      ...getSubmitButtonStyles(style?.submitButton),
      ...getSavedPaymentButtonStyles(style?.savedPaymentMethodButton),
      ...getDirectDebitMandateStyle(style?.directDebit?.mandate),
      ...getSmallPrintStyle(style?.smallPrint),
      ...getBackButtonStyle(style?.backButton),
      ...getSeparatorStyle(style?.separator),
      ...getErrorMessageStyle(style?.errorMessage),
    },
  };
};

///////////////////////////////////////////
// Manager
///////////////////////////////////////////

export class JSSStyleManager implements IStyleManager {
  private sheet: StyleSheet | null;

  private hostedFieldStyle: string | null;

  private style: CheckoutStyle | null;

  constructor() {
    this.sheet = null;
    this.hostedFieldStyle = null;
  }

  setStyle(style?: CheckoutStyle): void {
    this.style = style ?? null;
    jss.setup(preset());

    this.detachIfNeeded();

    const jssHostedFieldStyle = getJSSHostedFieldStyle(style);
    const jssStyle = getJSSGlobalStyle(style);

    this.sheet = jss.createStyleSheet({ '@global': jssStyle });
    this.sheet.attach();

    this.hostedFieldStyle = jss
      .createStyleSheet({ '@global': jssHostedFieldStyle })
      .toString();
  }

  getHostedFieldStyle(): string | null {
    return this.hostedFieldStyle;
  }

  getApmButtonStyle(): AlternativePaymentMethodButtonStyle | null {
    return this.style?.alternativePaymentMethodButton ?? null;
  }

  private detachIfNeeded() {
    if (this.sheet) {
      this.sheet.detach();
      this.sheet = null;
      this.hostedFieldStyle = null;
    }
  }
}

import creditCardType from 'credit-card-type';
import { ClientContext } from '../core/ClientContextFactory';
import { IFrameEventType } from '../core/IFrameEventType';
import { ElementID } from '../enums/Checkout';
import { ProgressEvent } from '../enums/Tokenization';
import { PaymentInstrumentType } from '../enums/Tokens';
import { delay } from '../utils/delay';
import { setValue } from './DomUtilities';

interface CardProxyConfig {
  onTokenizeProgress(ev: { type: ProgressEvent }): void;
  onTokenizeSuccess(data: unknown): void;
  onTokenizeStart?: () => void;
  card: {
    onCardMetadata: (e: { possibleTypes: string[] }) => void;
  };
}

interface SimulateTokenizeOptions {
  delayMillis?: number;
}

interface CreditCard {
  reset(): void;
}

export class CardProxy {
  private context: ClientContext;

  private config: CardProxyConfig;

  private data: { name: string; month: string; year: string; last4: string };

  private card: CreditCard;

  constructor(
    context: ClientContext,
    config: CardProxyConfig,
    card: CreditCard,
  ) {
    this.context = context;
    this.config = config;
    this.card = card;
    this.reset();
  }

  public async tokenize(options: SimulateTokenizeOptions = {}): Promise<void> {
    const { delayMillis = 1000 } = options;

    this.config.onTokenizeProgress({ type: ProgressEvent.TOKENIZE_STARTED });

    if (this.config.onTokenizeStart) {
      this.config.onTokenizeStart();
    }

    await delay(delayMillis);

    const fakeToken = {
      token: Math.random().toString(35).substring(2),
      tokenType: 'SINGLE_USE',
      paymentInstrumentType: PaymentInstrumentType.CARD,
      paymentInstrumentData: {
        cardholderName: this.data.name,
        expirationMonth: this.data.month,
        expirationYear: this.data.year,
        last4Digits: this.data.last4,
      },
    };

    this.config.onTokenizeSuccess(fakeToken);

    this.reset();
  }

  public resetFields(): void {
    this.reset();
    this.card.reset();
    this.sendKeys('cardholderName', '');
  }

  public async sendKeys(
    name: string,
    value: string,
    strokeDelay = 20,
  ): Promise<void> {
    const send =
      name === 'cardholderName'
        ? (val: string) => this.setById(ElementID.CARDHOLDER_NAME_INPUT, val)
        : (val: string) => this.setByEvent(name, val);

    if (!value) {
      send('');
      return;
    }

    for (let i = 0; i < value.length; i += 1) {
      send(value.substring(0, i + 1));
      await new Promise((resolve) => setTimeout(resolve, strokeDelay));
    }

    switch (name) {
      case 'cardNumber':
        this.data.last4 = value.substr(-4);
        this.updateCardTypes(value);
        break;
      case 'cardholderName':
        this.data.name = value;
        break;
      case 'expiryDate': {
        const [month, year] = value.split('/');
        this.data.month = month.trim();
        this.data.year = year.trim();
        break;
      }
      default:
        break;
    }
  }

  private setById(id: string, value: string): void {
    setValue(id, value);
  }

  private reset() {
    this.data = { last4: '', name: '', month: '', year: '' };
  }

  private setByEvent(name: string, value: string): void {
    this.context.messageBus.publish(name, {
      type: IFrameEventType.SET_VALUE,
      payload: value,
    });

    if (name === 'cardNumber') {
      this.updateCardTypes(value);
    }
  }

  private updateCardTypes(value: string): void {
    const types: string[] = [];

    creditCardType(value).forEach((info: { type: string }) => {
      if (info.type) {
        types.push(info.type);
      }
    });

    this.config.card.onCardMetadata({ possibleTypes: types });
  }
}

import createUnistore from 'unistore';
import { Scene } from '../enums/Checkout';

import {
  Provider,
  TypedUseSelector,
  useSelector as _useSelector,
  useStore,
} from './PreactUnistore';
import { ICheckoutState, SceneStage } from './BaseStore';
import CheckoutStore from './CheckoutStore';

const initialState: ICheckoutState = {
  sceneStates: {
    [Scene.LOADING]: {
      stage: SceneStage.Entered,
    },
  },
  isLoading: false,
  vault: {
    selected: null,
    items: [],
  },

  translation: null,
  tokens: [],
  apms: {
    selected: null,
    items: [],
  },

  options: {
    showSavePaymentMethod: false,
    hasCard: false,
    hasDirectDebit: false,
  },

  paymentMethods: {},

  submitButton: {
    isVisible: false,
    message: '',
  },

  error: {
    message: null,
  },

  smallPrint: {
    message: null,
  },

  directDebit: {
    customerName: null,
    customerAddress: null,
    customerIban: null,
  },
};

///////////////////////////////////////////
// API
///////////////////////////////////////////
export const createStore = () =>
  new CheckoutStore(createUnistore(initialState));
export const useCheckoutStore = useStore;
export const useSelector: TypedUseSelector<ICheckoutState> = _useSelector;
export { Provider };

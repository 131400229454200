import { Analytics, createAnalytics } from '../analytics/Analytics';
import { ApiEvent } from '../analytics/constants/enums';
import { PaymentFlow } from '../enums/Tokenization';
import { PaymentMethodType } from '../enums/Tokens';
import { PrimerClientCredentials } from '../types';
import { base64 } from '../utils/base64';
import { Environment } from '../utils/Environment';
import ModuleFactory from '../utils/ModuleFederation/ModuleFactory';
import { ScriptLoader } from '../utils/ScriptLoader';
import { Api } from './Api';
import { IFrameEventType } from './IFrameEventType';
import { IFrameFactory } from './IFrameFactory';
import { IFrameMessageBus } from './IFrameMessageBus';

interface PaymentMethodConfig {
  id: string;
  type: PaymentMethodType;
  options: {
    threeDSecureToken?: string;
    threeDSecureInitUrl?: string;
  };
}

interface ClientContextOptions {
  credentials: PrimerClientCredentials;
  thirdPartyScriptTimeout?: number;
}

interface DecodedClientToken {
  configurationUrl: string;
  accessToken: string;
  paymentFlow: PaymentFlow;
}

export interface ClientSessionInfo {
  assetsUrl: string;
  coreUrl: string;
  pciUrl: string;
  env: string;
  production: boolean;
  paymentFlow: PaymentFlow;
  paymentMethods: PaymentMethodConfig[];
  threeDSecureToken: Nullable<string>;
  threeDSecureInitUrl: Nullable<string>;
}

export interface ClientContext {
  iframes: IFrameFactory;
  messageBus: IFrameMessageBus;
  api: Api;
  analytics: Analytics;
  scriptLoader: ScriptLoader;
  session: ClientSessionInfo;
  moduleFactory: ModuleFactory;
}

interface ClientConfiguration {
  coreUrl: string;
  pciUrl: string;
  env: string;
  paymentMethods: PaymentMethodConfig[];
}

export const ClientContextFactory = {
  async create(options: ClientContextOptions): Promise<ClientContext> {
    const { accessToken, configurationUrl, paymentFlow } = decodeClientToken(
      options.credentials,
    );

    const assetsUrl = getAssetsURL();

    const messageBus = new IFrameMessageBus();
    const iframes = new IFrameFactory({ messageBus, assetsUrl });
    const api = new Api({ iframes, messageBus, accessToken });

    const analytics = createAnalytics();
    analytics.call({ event: ApiEvent.startedCheckout });
    analytics.time({ event: ApiEvent.loadedCheckoutUi });
    analytics.time({ event: ApiEvent.completedCheckout });

    const { data, error } = await api.get<ClientConfiguration>(
      configurationUrl,
    );

    if (error) {
      throw new Error(`Failed to initialize client: ${error}`);
    }

    if (!data) {
      throw new Error('Failed to initialize client');
    }

    analytics.setSdkEnvironment(data.env);

    messageBus.publish('api-controller', {
      type: IFrameEventType.SESSION,
      payload: {
        coreUrl: data.coreUrl,
        pciUrl: data.pciUrl,
      },
    });

    analytics.call({ event: ApiEvent.loadedCheckoutUi });

    const scriptLoader = new ScriptLoader(options);
    const moduleFactory = new ModuleFactory(scriptLoader);

    return {
      iframes,
      messageBus,
      api,
      analytics,
      scriptLoader,
      moduleFactory,
      session: {
        assetsUrl,
        coreUrl: Environment.get('PRIMER_CORE_API_URL', data.coreUrl),
        pciUrl: Environment.get('PRIMER_PCI_API_URL', data.pciUrl),
        env: data.env,
        production: data.env === 'PRODUCTION',
        paymentMethods: data.paymentMethods,
        paymentFlow: paymentFlow || PaymentFlow.DEFAULT,

        // TODO: refactor the usage of 3DS tokens
        get threeDSecureToken(): Nullable<string> {
          const card = data?.paymentMethods.find(
            (elm) => elm.type === PaymentMethodType.PAYMENT_CARD,
          );

          if (!card) {
            return null;
          }

          return card.options.threeDSecureToken || null;
        },
        get threeDSecureInitUrl(): Nullable<string> {
          const card = data?.paymentMethods.find(
            (elm) => elm.type === PaymentMethodType.PAYMENT_CARD,
          );

          if (!card) {
            return null;
          }

          return card.options.threeDSecureInitUrl || null;
        },
      },
    };
  },
};

function decodeClientToken(
  credentials: PrimerClientCredentials,
): DecodedClientToken {
  if (!credentials) {
    throw new TypeError(
      'Missing required `credentials` in Primer client constructor!',
    );
  }

  if (!credentials.clientToken) {
    throw new TypeError(
      'Missing required `credentials.clientToken` in Primer client constructor!',
    );
  }

  const tokens = credentials.clientToken.split('.');
  const encoded = tokens.length === 1 ? tokens[0] : tokens[1];

  let decoded = null;

  try {
    decoded = JSON.parse(base64.decode(encoded));
  } catch (e) {
    /* empty */
  }

  if (decoded == null) {
    throw new TypeError('The provided `credentials.clientToken` is malformed!');
  }

  return decoded as DecodedClientToken;
}

function getAssetsURL(): string {
  const version = Environment.get('PRIMER_SDK_VERSION');

  return Environment.get(
    'PRIMER_ASSETS_URL',
    Environment.get('PRIMER_BUILD_INTEGRATION_BUILDER', false)
      ? `https://assets.primer.io/primer-sdk-web/ib/${version}`
      : `https://assets.primer.io/primer-sdk-web/${version}`,
  );
}

import { FunctionalComponent, createContext, h } from 'preact';
import { useCallback, useContext, useLayoutEffect, useRef } from 'preact/hooks';

type IContext = {
  wait: (id: string) => void;
  ready: (id: string) => void;
};

const Context = createContext<IContext>({
  wait: () => '',
  ready: () => '',
});

export const useWait = (onReady: () => void, items?: Array<string>) => {
  const blockingItems = useRef({}).current;

  const wait = useCallback((id: string) => {
    blockingItems[id] = true;
  }, []);

  const ready = useCallback((id: string) => {
    blockingItems[id] = false;
    const isReady = !Object.entries(blockingItems).find(
      ([, blocking]) => blocking,
    );

    if (isReady) {
      onReady();
    }
  }, []);

  useLayoutEffect(() => {
    items?.forEach(wait);
  }, []);

  return { wait, ready };
};

export const useWaitForComponent = () => useContext(Context);

type Props = {
  onReady: () => void;
};

export const WaitForComponents: FunctionalComponent<Props> = ({
  onReady,
  children,
}) => {
  const { wait, ready } = useWait(onReady);

  return (
    <Context.Provider value={{ wait, ready }}>{children}</Context.Provider>
  );
};

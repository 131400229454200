import { getAttribute, massToggle } from './DomUtilities';
import { CheckoutOptions, FormState } from '../types';
import { ClassName, ElementID } from '../enums/Checkout';
import { cardTypeChangeHandler } from './cardTypeChangeHandler';
import { fieldChangeHandler } from './fieldChangeHandler';
import { IViewUtils } from './ui/types';
import CheckoutStore from '../store/CheckoutStore';

export const CheckoutPaymentMethodConfig = {
  create(
    store: CheckoutStore,
    viewUtils: IViewUtils,
    options: CheckoutOptions,
  ): Record<string, unknown> {
    const {
      countryCode,
      purchaseInfo,
      locale,
      paypal = {},
      applePay = {},
      googlePay = {},
    } = options;

    const config = {
      countryCode,
      locale,
      purchaseInfo,
      mountImmediately: false,
      logWarnings: false,
      paypal: {
        container: '#primer-checkout-apm-paypal',
        buttonColor: 'gold',
        ...paypal,
      },
      googlePay: {
        container: '#primer-checkout-apm-googlePay',
        buttonColor: 'black',
        ...googlePay,
      },
      applePay: {
        container: '#primer-checkout-apm-applePay',
        buttonStyle: 'white-outline',
        ...applePay,
      },
    };

    if (store.hasDirectDebit) {
      Object.assign(config, {
        directDebit: {
          customerCountryCode: options.directDebit?.customerCountryCode,
          customerName: inputValueGetter(ElementID.DD_CUSTOMER_NAME_INPUT),
          customerEmail: inputValueGetter(ElementID.DD_CUSTOMER_EMAIL_INPUT),
          customerAddressLine1: inputValueGetter(
            ElementID.DD_CUSTOMER_ADDRESS_LINE1,
          ),
          customerAddressLine2: inputValueGetter(
            ElementID.DD_CUSTOMER_ADDRESS_LINE2,
          ),
          customerCity: inputValueGetter(ElementID.DD_CUSTOMER_ADDRESS_CITY),
          customerPostalCode: inputValueGetter(
            ElementID.DD_CUSTOMER_ADDRESS_POSTAL_CODE,
          ),
          iban: inputValueGetter(ElementID.DD_IBAN_INPUT),
        },
      });
    }

    if (store.hasCard) {
      const card = options.card || {};

      const vault = card.vault
        ? card.vault
        : () => getAttribute(ElementID.SAVE_PAYMENT_METHOD_CHECKBOX, 'checked');

      Object.assign(config, {
        card: {
          onCardMetadata: cardTypeChangeHandler,
          vault,
          cardholderName: () =>
            getAttribute(ElementID.CARDHOLDER_NAME_INPUT, 'value'),
          css: card.css || viewUtils.styleManager.getHostedFieldStyle(),
          stylesheets: options?.style?.stylesheets,

          onChange(formState: FormState) {
            if (formState.dirty) {
              store.selectVault(null);
              massToggle(
                ClassName.SAVED_PAYMENT_METHOD,
                ClassName.SELECTED,
                () => false,
              );
            }
          },
          fields: {
            cardNumber: {
              container: '#primer-checkout-card-number-input',
              placement: 'prepend',
              placeholder: '1234 1234 1234 1234',
              ariaLabel: store.getTranslations().cardNumber,
              onChange: fieldChangeHandler(
                'primer-checkout-card-number',
                viewUtils.fieldUtils,
              ),
            },
            expiryDate: {
              container: '#primer-checkout-card-expiry-input',
              placeholder: store.getTranslations().cardExpiryPlaceholder,
              ariaLabel: store.getTranslations().cardExpiry,
              onChange: fieldChangeHandler(
                'primer-checkout-card-expiry',
                viewUtils.fieldUtils,
              ),
            },
            cvv: {
              container: '#primer-checkout-card-cvv-input',
              placeholder: '•••',
              ariaLabel: store.getTranslations().cardCVV,
              onChange: fieldChangeHandler(
                'primer-checkout-card-cvv',
                viewUtils.fieldUtils,
              ),
            },
          },
        },
      });
    }

    return config;
  },
};

function inputValueGetter(elementId: ElementID) {
  return () => getAttribute(elementId, 'value');
}

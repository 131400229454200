import { resolveElement } from '../utils/resolveElement';
import { toggleClass } from '../utils/toggleClass';
import { ClassName, ElementID, Scene } from '../enums/Checkout';
import { delay } from '../utils/delay';
import { getFirstFocusableChild } from './focus';
import { Environment } from '../utils/Environment';

export class SceneTransition {
  onEnter(scene: Scene): Promise<void> {
    return this.transition(scene, true);
  }

  onExit(scene: Scene): Promise<void> {
    return this.transition(scene, false);
  }

  private async transition(scene: Scene, isEntering: boolean): Promise<void> {
    const root = document.getElementById(ElementID.ROOT_CONTENT) as HTMLElement;
    const node = resolveElement(scene);

    if (node == null) {
      return;
    }

    if (isEntering) {
      root.getBoundingClientRect();
      root.style.height = `${root.offsetHeight}px`;
    }

    // Clear previous and add current
    toggleClass(scene, isEntering ? ClassName.EXIT : ClassName.ENTER, false);
    toggleClass(
      scene,
      isEntering ? ClassName.EXITING : ClassName.ENTERING,
      false,
    );
    toggleClass(
      scene,
      isEntering ? ClassName.EXITED : ClassName.ENTERED,
      false,
    );

    toggleClass(scene, isEntering ? ClassName.ENTER : ClassName.EXIT, true);
    node.getBoundingClientRect();

    // On entering
    toggleClass(
      scene,
      isEntering ? ClassName.ENTERING : ClassName.EXITING,
      true,
    );

    node.getBoundingClientRect();

    if (isEntering) {
      root.getBoundingClientRect();
      root.style.height = `${node.offsetHeight}px`;
    }

    if (isEntering) {
      if (!Environment.get('PRIMER_BUILD_INTEGRATION_BUILDER', false)) {
        getFirstFocusableChild(scene)?.focus();
      }
    }

    await delay(700);

    // On entered
    toggleClass(scene, isEntering ? ClassName.ENTERED : ClassName.EXITED, true);

    if (isEntering) {
      root.getBoundingClientRect();
      root.style.height = 'auto';
    }
  }
}

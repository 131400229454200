import { defaultFieldMetadata } from '../../utils/field-metadata';
import { funcify } from '../../utils/funcify';
import { ProgressEvent } from '../../enums/Tokenization';
import { HTMLElementEventType } from '../../enums/HTMLElementEventType';
import { noop } from '../../utils/noop';
import { nextTick } from '../../utils/nextTick';
import { locateElement } from '../../utils/dom';
import { addEventListener } from '../../utils/addEventListener';
import { iosInputBlurFix } from '../../utils/iosInputBlurFix';
import { BasePaymentMethod } from '../BasePaymentMethod';
import { IFrameEventType } from '../../core/IFrameEventType';
import { ErrorCode, PrimerClientError } from '../../errors';

export default class CreditCard extends BasePaymentMethod {
  constructor(context, opts) {
    super('Card');
    const options = normalizeOptions(opts);

    this.context = context;
    this.options = options;
    this.fields = {};
    this.meta = { submitted: false };
  }

  async createField(name, config) {
    if (config == null) {
      return null;
    }

    const normalized = name.toLowerCase();
    const id = `primer-${normalized}-field`;

    const field = {
      name,
      meta: { ...defaultFieldMetadata },
      onChange: config.onChange || noop,
    };

    this.fields[name] = field;

    return new Promise((resolve) => {
      field.frame = this.context.iframes.create({
        filename: 'hosted-input.html',
        container: config.container,
        placement: config.placement || 'append',
        onReady: resolve,
        meta: {
          id,
          name,
          placeholder: config.placeholder,
          css: this.options.css,
          stylesheets: this.options.stylesheets,
          ariaLabel: config.ariaLabel,
        },
      });
    });
  }

  async mount() {
    const { submitButton, fields } = this.options;
    const button = locateElement(submitButton);

    if (button) {
      addEventListener(button, HTMLElementEventType.CLICK, () => {
        this.tokenize();
      });
    }

    this.context.messageBus.on(IFrameEventType.INPUT_METADATA, (e) => {
      const { source } = e.meta;
      const field = this.fields[source];

      field.meta = e.payload;

      runFieldUpdates(this, [field]);
    });

    this.context.messageBus.on(IFrameEventType.CARD_METADATA, (e) => {
      this.options.onCardMetadata(e.payload);
    });

    this.context.messageBus.on(IFrameEventType.IOS_BLUR_FIX, (e) => {
      const { source } = e.meta;
      const field = this.fields[source];

      if (field == null) {
        return;
      }

      iosInputBlurFix(field.frame);
    });

    await Promise.all([
      nextTick(() => this.createField('cardNumber', fields.cardNumber)),
      nextTick(() => this.createField('cvv', fields.cvv)),
      nextTick(() => this.createField('expiryDate', fields.expiryDate)),
    ]);
  }

  reset() {
    this.meta.submitted = false;
    Object.keys(this.fields).forEach((name) => {
      this.context.messageBus.publish(name, {
        type: IFrameEventType.RESET_FIELD,
      });
    });
  }

  async tokenize() {
    this.meta.submitted = true;

    const valid = validate(this);

    runFieldUpdates(this);

    if (this.options.disabled()) {
      return;
    }

    this.context.progress.emit(ProgressEvent.TOKENIZE_STARTED);

    if (!valid) {
      return;
    }

    const vault = this.options.vault();
    const cardholderName = this.options.cardholderName();

    const body = {
      paymentInstrument: {
        number: '$.cardNumber',
        cvv: '$.cvv',
        expirationMonth: '$.expiryDate.month',
        expirationYear: '$.expiryDate.year',
      },
    };

    if (cardholderName) {
      body.paymentInstrument.cardholderName = cardholderName;
    }

    if (vault) {
      body.tokenType = 'MULTI_USE';
      body.paymentFlow = 'VAULT';
    }

    const response = await this.context.api.post('/payment-instruments', body);

    this.handleTokenizationResponse(response);

    runFieldUpdates(this);
  }

  handleTokenizationResponse(response) {
    if (!response.error) {
      this.context.progress.emit(ProgressEvent.TOKENIZE_SUCCESS, response.data);
      return;
    }

    const error = errorFromAPIResponse(response.error);

    this.context.progress.emit(ProgressEvent.TOKENIZE_ERROR, error);

    if (error.code === ErrorCode.CARD_NUMBER_ERROR) {
      this.context.messageBus.publish('cardNumber', {
        type: IFrameEventType.UPDATE_METADATA,
        payload: {
          error: 'invalid',
          valid: false,
        },
      });
    }
  }

  async validate() {
    this.meta.submitted = true;
    runFieldUpdates(this);

    return {
      valid: validate(this),
    };
  }
}

/**
 *
 * @param {CreditCard} instance
 */
function validate(instance) {
  const fields = Object.values(instance.fields);

  let numErrors = 0;

  for (let i = 0; i < fields.length; i += 1) {
    const field = fields[i];

    if (field && field.meta.error) {
      numErrors += 1;
    }
  }

  return numErrors === 0;
}

/**
 *
 * @param {CreditCard} instance
 */
function runFieldUpdates(instance, fields = Object.values(instance.fields)) {
  const formState = deriveFormState(instance);

  instance.options.onChange(formState);

  fields.forEach((field) => {
    if (!field.meta.submitted && instance.meta.submitted) {
      instance.context.messageBus.publish(field.name, {
        type: IFrameEventType.SET_SUBMITTED,
        payload: true,
      });
    }

    const meta = { ...field.meta, ...instance.meta };

    meta.error = meta.error ? instance.context.translations[meta.error] : null;

    field.onChange({ meta });
  });
}

/**
 *
 * @param {Primer.CreditCardConfig} opts
 */
function normalizeOptions(opts) {
  return {
    ...opts,
    cardholderName: funcify(opts.cardholderName),
    vault: funcify(opts.vault),
    onChange: opts.onChange || noop,
    onCardMetadata: opts.onCardMetadata || noop,
    disabled: opts.disabled || funcify(false),
  };
}

/**
 *
 * @param {CreditCard} instance
 */
function deriveFormState(instance) {
  const { submitted } = instance.meta;
  const allFields = Object.values(instance.fields);
  const state = allFields.reduce(
    (acc, { meta }) => ({
      dirty: some(acc, meta, 'dirty'),
      touched: some(acc, meta, 'touched'),
      active: some(acc, meta, 'active'),
      valid: every(acc, meta, 'valid'),
    }),
    {},
  );

  return { ...state, submitted };
}

function some(a, b, name) {
  return a[name] || b[name];
}

function every(a, b, name) {
  return a[name] && b[name];
}

function errorFromAPIResponse(err) {
  switch (err.errorId) {
    case 'InvalidCardNumber':
      return PrimerClientError.fromErrorCode(ErrorCode.CARD_NUMBER_ERROR, err);
    default:
      return PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, err);
  }
}

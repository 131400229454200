import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { IAnalyticsProvider } from './AnalyticsProvider';
import { AnalyticsAction } from '../models/AnalyticsAction';
import { Environment } from '../../utils/Environment';

const MOCK_TOKEN = Environment.get<string>('MOCK_MIXPANEL_TOKEN');
const END_POINT = Environment.get<string>('ANALYTICS_ENDPOINT');
const sdkVersion = Environment.get<string>('PRIMER_SDK_VERSION');

export class MixpanelProvider implements IAnalyticsProvider {
  mixpanel: Mixpanel;

  sdkEnvironment: string | undefined;

  constructor() {
    const token = MOCK_TOKEN ?? 'TOKEN';
    this.mixpanel = mixpanel.init(token, { api_host: END_POINT }, '');
  }

  call(action: AnalyticsAction): void {
    const payload = action.data ?? {};

    if (sdkVersion) {
      payload.sdkVersion = sdkVersion;
    }

    if (this.sdkEnvironment) {
      payload.sdkEnvironment = this.sdkEnvironment;
    }

    mixpanel.track(action.event, payload);
  }

  time(action: AnalyticsAction): void {
    mixpanel.time_event(action.event);
  }
}

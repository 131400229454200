export const translation = {
  navigateToPaymentMethods: 'Show other ways to pay',
  payWithCard: 'Or pay with card',
  cardNumber: 'Card number',
  cardExpiry: 'Expiry',
  cardExpiryPlaceholder: 'MM/YY',
  cardCVV: 'CVV',
  cardholderName: 'Name on card',
  cardholderNamePlaceholder: 'Full name',
  previouslyUsed: 'Previously used:',
  forgetPaymentMethods: 'Forget payment methods',
  forgetSavedCard: 'Forget saved card ending in {{last4Digits}}',
  required: 'Required',
  invalid: 'Invalid',
  forgetPayPal: 'Forget PayPal account',
  savePaymentMethod: 'Save payment method',
  managePaymentMethods: 'Manage your payment methods',
  savedPaymentMethodsEmpty:
    'Enter your card details below to add a new payment method',
  newPaymentMethod: 'Add a new payment method',
  cardEndingWith: 'Card ending with',
  accountHolderName: 'Account holder name',
  pay: 'Pay',
  addCard: 'Add Card',
  confirm: 'Confirm',
  directDebitSmallPrint: `By signing this mandate form, you authorise (A) {{companyName}} to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instruction from {{companyName}}`,
  otherWaysToPay: 'Other ways to pay',
  // Payment methods dropdown
  editPaymentMethods: 'Edit saved payment methods',
  confirmCardDeletion: 'Confirm - Delete Card',
  confirmPayPalDeletion: 'Confirm - Delete PayPal',
  delete: 'Delete',
  cancel: 'Cancel',
  // Direct Debit Form
  accountHolder: 'Account Holder',
  address: 'Address',
  addressLine1: 'Address line 1',
  addressLine2: 'Address line 2',
  fullName: 'First and last name',
  email: 'Email',
  city: 'City',
  postalCode: 'Postal code',
  setUpDirectDebit: 'Set up Direct Debit',
};

import ProgressNotifier from './ProgressNotifier';
import { Translations } from '../assets/translations/Translations';

const PaymentMethodContext = {
  create(clientContext, options) {
    const {
      countryCode,
      purchaseInfo,
      onTokenizeError,
      onTokenizeStart,
      onTokenizeProgress,
      onTokenizeSuccess,
      onTokenizeEnd,
    } = options;

    const context = { ...clientContext, purchaseInfo, countryCode };

    context.progress = new ProgressNotifier({
      onTokenizeError,
      onTokenizeStart,
      onTokenizeProgress,
      onTokenizeSuccess,
      onTokenizeEnd,
    });

    context.translations = Translations.get(options.locale);

    return context;
  },
};

export default PaymentMethodContext;
